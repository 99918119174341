import React from 'react';

import ConfigureAmount from './configure-amount';
import ConfigureMessage from './configure-message';

import './gift-card.less';

export const isGiftCard = product => {
    if (!product) {
        return false;
    }
    return ['VGC', 'PGC'].includes(product.product_type);
};

const GiftCard = ({ product, Amount, Message }) => {
    if (!isGiftCard(product)) {
        return null;
    }
    return (
        <>
            <ConfigureAmount product={product} Amount={Amount} selectDefault />
            <ConfigureMessage product={product} Message={Message} />
        </>
    );
};

export default GiftCard;
