import React from 'react';
import { Typography, Link } from '@hpstellar/core';

export default ({
    priceObj,
    disclaimerMessage = 'You will not be charged until the product is shipped.',
    faqId = 'faq',
    ctaText = 'Learn More',
}) => {
    const { preOrder } = priceObj || {};
    if (!preOrder) {
        return null;
    }
    return (
        <Typography variant="bodySmall" className={'pre-order-disclaimer'}>
            <span className="pre-order-disclaimer-text">{disclaimerMessage}</span>
            <Link size="tiny" to={`#${faqId}`} variation="body" weight="regular">
                {ctaText}
            </Link>
        </Typography>
    );
};
