import React from 'react';

import TitleSection from './title-section';
import Specs from './specs-section';
import CartSection from './cart-section';

export default ({}) => {
    return (
        <div className="hero-section">
            <TitleSection showFor={['desktop', 'tablet']} />
            <Specs />
            <CartSection />
        </div>
    );
};
