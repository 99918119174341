import React from 'react';
import { CarePacksModal, PriceBlock, Modal, Typography, Button } from '@hpstellar/core';

import { Helpers } from '../../../core/src/helpers';
import LearnMore from './learn-more';

const getRows = (rows, carePackItems, selectedCarepack, onChangeSelection) => {
    const skuRow = {
        data: carePackItems.map(({ sku }) => (sku !== 'default' ? sku : '')),
    };
    const priceRow = {
        data: carePackItems.map(p => (p.price ? <PriceBlock {...p.price} style="slim" /> : 'Included with device')),
        fieldName: 'Price',
    };
    const selectionRow = {
        data: carePackItems.map(item => {
            const event = { target: { value: item.sku } };
            return item.sku === selectedCarepack.sku ? (
                <Typography isCaps style={{ color: '#767676' }} variant="boldS">
                    Included
                </Typography>
            ) : (
                <Button
                    color="dark"
                    size="small"
                    variation="primary"
                    data-gtm-id="product"
                    data-gtm-category="linkClick"
                    data-gtm-value={item.gtmValue}
                    onClick={() => onChangeSelection(event)}
                >
                    Select
                </Button>
            );
        }),
    };
    return [skuRow, ...rows, priceRow, selectionRow];
};

const CompareCarePacks = ({
    isOpen,
    carePackItems,
    onChangeSelection,
    selectedCarepack,
    modalClose,
    serviceBundle,
}) => {
    const { compareModal } = serviceBundle || {};
    const { headers, rows, modalTitle, highlightedIndex } = compareModal || {};
    if (compareModal) {
        return (
            <CarePacksModal
                className="care-pack-compare-modal"
                description={modalTitle && <div dangerouslySetInnerHTML={Helpers.createMarkup(modalTitle)} />}
                data={{ headers, rows: getRows(rows, carePackItems, selectedCarepack, onChangeSelection) }}
                isOpen={isOpen}
                onClose={modalClose}
            />
        );
    }
    return (
        <Modal
            className="learnMoreModal"
            isOpen={isOpen}
            onModalClose={modalClose}
            horizontalAlignment="center"
            verticalAlignment="center"
            withBackdrop
        >
            <LearnMore
                items={carePackItems}
                onChange={onChangeSelection}
                selected={selectedCarepack.sku}
                modalClose={modalClose}
            />
        </Modal>
    );
};

export default React.memo(CompareCarePacks);
