const getDefaultPDPTitle = (product, excludeUpc = false) => {
    let { name = '', sku } = product || {};
    let upcValue = product.prdClass === 'EXT' && product.upc && !excludeUpc ? ` | UPC: ${product.upc}` : '';
    let skuValue = name.indexOf(sku) < 0 ? ` (${sku})` : '';
    return `${name}${skuValue}${upcValue}`;
};

export const getProductDescription = product => {
    const { seo = {}, prdClass } = product || {};
    const title = getDefaultPDPTitle(product, true);
    if (seo.description) {
        return seo.description;
    }

    return prdClass === 'EXT'
        ? `Find all product features, specs, accessories, reviews and offers for ${title}. Save with Free Shipping when you shop online with HP.`
        : `Save with Free Shipping when you shop online with HP. Find all product features, specs, accessories, reviews and offers for ${title}.`;
};

const getEolTitle = product => {
    let { attributes } = product || {};
    let isPrinter = attributes && attributes.suppliesLinkUrl;
    let defaultTitle = getDefaultPDPTitle(product);
    if (!isPrinter) {
        return defaultTitle;
    }

    return `${defaultTitle} Ink & Toner Supplies`;
};

export const getPDPTitle = product => {
    let { eol, seo = {} } = product || {};

    if (seo.title) {
        return seo.title;
    }

    if (eol) {
        return getEolTitle(product);
    }

    return getDefaultPDPTitle(product);
};
