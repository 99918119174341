import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Helpers } from '../../core/src/helpers';
import '../css/inventory-message.less';

const InventoryMessage = ({
    product = {},
    price,
    defaultText = '',
    showOOS,
    children,
    priceLabels,
    showPreOrderMsg,
}) => {
    const isInstantInk = /instant ink prepaid ecode/gi.test(product.name);
    const { isComingSoon, shippingMessage } = product;
    const { invAvaDate, stock, preOrder } = price;
    const { poboShipMsg = 'Estimated shipping date:' } = priceLabels || {};
    let shippingMsg;
    let isBackOrder = !!invAvaDate;
    if (shippingMessage) {
        //customized shipping message overrides everything
        shippingMsg = shippingMessage;
    } else if (isInstantInk) {
        shippingMsg = 'Prepaid pin code will be emailed';
    } else if (preOrder && showPreOrderMsg) {
        shippingMsg = `${poboShipMsg} ${invAvaDate}`;
    } else if (isBackOrder && !preOrder) {
        shippingMsg = `Ships by ${invAvaDate}`;
    } else {
        shippingMsg = defaultText;
    }

    let stockMsg = '';
    if (stock <= 5 && stock > 0) {
        stockMsg = `Only ${stock} Left!`;
    }

    const showMessage = stock !== 0 && (stockMsg || shippingMsg) && !isComingSoon;
    const className = isBackOrder ? ' back-order' : '';

    if (children && showOOS) {
        return children({ stock, className, shippingMsg, price });
    } else if (children && !showMessage) {
        return children({ shippingMsg: null, stockMsg: null, className, price });
    } else if (children) {
        return children({ shippingMsg, stockMsg, className, price });
    }
    return showMessage ? <div className={`inventory-msg${className}`}>{stockMsg || shippingMsg}</div> : null;
};

InventoryMessage.propTypes = {
    /*product that displays the inventory message*/
    product: PropTypes.object,
    /*price and stock info associated with the product*/
    price: PropTypes.object.isRequired,
    /*default shipping message*/
    defaultMessage: PropTypes.string,
    /*checks to see if "out of stock" message needs to be displayed as the inventory message*/
    showOOS: PropTypes.bool,
};

export const mapStateToProps = (state, ownProps) => {
    const price = Helpers.getProductPrice(ownProps.product, state.productData.productInfo.productPrices);
    const { etrStoreData } = state;
    const { labels: priceLabels } = etrStoreData;
    return {
        price,
        priceLabels,
    };
};

export { InventoryMessage as _UnconnectedInventoryMessage };
export default connect(mapStateToProps, null)(InventoryMessage);
