import React from 'react';

import withPageComponents from '../../../../page/components/with-page-components';
import useProductPrice from '../../../../hooks/useProductPrice';
import useProductImpressions from '../../../../hooks/useProductImpressionsV2';
import ConsumerFinanceWidget from '../../../../product/components/consumer-finance-widget';
import DeliveryMessage from '../pdp-delivery-message';
import PreOrderDisclaimer from '../pre-order-disclaimer-link';
import StockIndicator from '../stock-indicator';
import CTAWrapper from '../cta';
import PriceBlock from './price-container';
import GiftCardContainer from './gift-card-container';
import { ReviewSection } from '../title-section';
import { useEnrollmentKit } from '../enrollment-kit';
import EnrollmentKitCta, { getEnrollmentKitPriceProps } from './enrollment-kit-cta';
import './cart-section.less';

const cartSection = ({
    productInitial,
    productInitialPrice,
    pdpCarePackBundle,
    pdpShadowCTO,
    templateKey,
    bazaarVoiceProps,
    device,
}) => {
    const { shipDate } = productInitial || {};
    const { carepacks } = pdpCarePackBundle || {};
    const { prices } = useProductPrice([productInitial, ...(Array.isArray(carepacks) ? carepacks : [])]);
    const ekProps = useEnrollmentKit(productInitial);
    const { kitDisplayPrice, enrollmentKitProps } = ekProps;
    const enrollmentKitEnabled = !!enrollmentKitProps;
    const { sku } = productInitial || {};
    const priceObj = (prices || {})[sku];
    const { backOrderFlag, preOrder } = priceObj || {};
    const { shadowCTOEnabled } = pdpShadowCTO || {};
    const hideStockMessage = preOrder || backOrderFlag || shadowCTOEnabled;
    const subscriptionPrices = getEnrollmentKitPriceProps(kitDisplayPrice);
    const priceProps = { subscriptionPrices };
    const productsWithAnalytics = useProductImpressions({
        products: productInitial && [productInitial],
        list: 'pdp',
        componentKey: 'productInitial',
        key: sku,
        isMainSku: true,
        // nonImpression: true,
    });

    const prdInitial = (Array.isArray(productsWithAnalytics) && productsWithAnalytics[0]) || productInitial;

    if (!prdInitial) return null;
    const ctaBtnProps = enrollmentKitEnabled ? { variation: 'secondary' } : {};
    return (
        <div id="addtocart" className={'pdp-cart-section'}>
            {!shadowCTOEnabled && (
                <>
                    <ReviewSection
                        productInitial={productInitial}
                        bazaarVoiceProps={bazaarVoiceProps}
                        templateKey={templateKey}
                        device={device}
                    />
                    <GiftCardContainer>
                        <PriceBlock className="pdp-price-row" priceProps={priceProps} hasPriceSeoText={false} />
                    </GiftCardContainer>
                    <ConsumerFinanceWidget
                        product={prdInitial}
                        price={priceObj || productInitialPrice}
                        variant="section"
                    />
                </>
            )}
            <PreOrderDisclaimer priceObj={priceObj} />
            {backOrderFlag && !hideStockMessage && <StockIndicator showFor={['mobile']} />}
            <CTAWrapper
                hideForShadowCTO={true}
                secondaryCTA={enrollmentKitEnabled ? <EnrollmentKitCta {...ekProps} /> : undefined}
                ctaBtnProps={ctaBtnProps}
                className={enrollmentKitEnabled ? 'enrollment-kit-container' : undefined}
                loaderEnabled={true}
            />
            {!shadowCTOEnabled && <DeliveryMessage />}
            {!hideStockMessage ? (
                <StockIndicator showFor={['tablet', 'desktop']} shipDate={shipDate} />
            ) : (
                <div style={{ paddingBottom: '1rem' }}></div>
            )}
        </div>
    );
};

cartSection.whyDidYouRender = true;

export default withPageComponents(cartSection, {
    components: ['productInitial', 'productInitialPrice', 'pdpCarePackBundle', 'pdpShadowCTO'],
    device: true,
});
