import React, { useMemo } from 'react';
import { Overview, KeySellingPoints } from '@hpstellar/core';

import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
import withPageComponents from '../../../page/components/with-page-components';
import useDisclaimerTabs from '../../../hooks/useDisclaimerTabs';
import useDynamicPriceHtml from '../../../hooks/useDynamicPriceHtml';
import useProductPrice from '../../../hooks/useProductPrice';
import EtilizeContent from '../../../shared/components/etilize-product';
import WorldSyncContent from './world-sync-content';
import { getSubscriptionTermsHTML } from '../../components/tabs/subscription-terms';

import { Helpers } from '../../../core/src/helpers';

import './pdp-overview.less';

const setSubscriptionTerms = (description, priceObj) => {
    if (!description) {
        return description;
    }
    const subscriptionTerms = getSubscriptionTermsHTML(priceObj);
    const overviewContent = Array.isArray(description) ? description : [description];
    if (subscriptionTerms) {
        const withSubscriptions = overviewContent.slice();
        withSubscriptions.splice(overviewContent.length > 0 ? 1 : 0, 0, subscriptionTerms);
        return withSubscriptions.join('<br>');
    }
    return overviewContent.join('<br>');
};

const OverviewContent = ({ productInitial, pdpFeatures, category }) => {
    const { prices } = useProductPrice([productInitial]);
    const priceObj = prices[productInitial.sku];
    const { pm_category = '', sku } = productInitial;
    const { keyPoints, description, bulletPoints, bundleComponents } = pdpFeatures || {};
    const categoryName = pm_category.toLowerCase();
    const keyPointsData = keyPoints || bundleComponents;
    const hasBullets = bulletPoints && bulletPoints.length > 0;
    const overViewContent = setSubscriptionTerms(description, priceObj);
    useDynamicPriceHtml([overViewContent, bulletPoints], { selectorPrefix: '.pdp-overview' });

    const keySellingPoints = useMemo(
        () =>
            keyPointsData &&
            keyPointsData.map(({ data, bundleData }, idx) => {
                return (
                    <KeySellingPoints
                        key={idx}
                        bundleData={bundleData}
                        data={data.map(ksp => ({
                            ...ksp,
                            description: ksp?.description && (
                                <span dangerouslySetInnerHTML={Helpers.createMarkup(ksp.description)} />
                            ),
                            tag: 'h3',
                        }))}
                        className={categoryName.indexOf('services') > -1 ? 'services' : undefined}
                    />
                );
            }),
        [keyPointsData]
    );

    return (
        <>
            {(overViewContent || bulletPoints) && (
                <Overview
                    productCategory={category}
                    bulletPoints={bulletPoints}
                    description={
                        hasBullets &&
                        overViewContent && <span dangerouslySetInnerHTML={Helpers.createMarkup(overViewContent)} />
                    }
                    title=""
                >
                    {!hasBullets && <div dangerouslySetInnerHTML={Helpers.createMarkup(overViewContent)} />}
                </Overview>
            )}
            {keySellingPoints}
        </>
    );
};

const pdpOverview = ({ productInitial, pdpFeatures, category }) => {
    const [, setDisclaimerTab] = useDisclaimerTabs();
    const { oid, display_3pprichcontent } = productInitial || {};
    if (!productInitial || (!pdpFeatures && display_3pprichcontent !== 'Yes')) {
        return null;
    }
    const { keyPoints, bundleComponents, description, bulletPoints } = pdpFeatures || {};
    const keyPointsData = keyPoints || bundleComponents;
    const classNameKeyPoints = keyPointsData ? ' with-key-points' : '';

    const interceptClick = ev => {
        if (ev.target.classList.contains('footerLink')) {
            setDisclaimerTab('specs');
        }
    };
    const hasOverview = description || bulletPoints;

    return (
        <div
            className={`pdp-overview${classNameKeyPoints}`}
            id={!hasOverview ? 'overview' : null}
            onClick={interceptClick}
        >
            {oid && display_3pprichcontent === 'Yes' ? (
                <EtilizeContent oid={oid} style={{ minHeight: '400px' }}>
                    <OverviewContent productInitial={productInitial} pdpFeatures={pdpFeatures} category={category} />
                </EtilizeContent>
            ) : (
                <>
                    <OverviewContent productInitial={productInitial} pdpFeatures={pdpFeatures} category={category} />
                    <AboveTheFoldOnlyServerRender skip={true} offset={500}>
                        <WorldSyncContent />
                    </AboveTheFoldOnlyServerRender>
                </>
            )}
        </div>
    );
};

export default withPageComponents(pdpOverview, {
    components: ['productInitial', 'pdpFeatures'],
});
