import React from 'react';
import { Typography } from '@hpstellar/core/visId';

import './selected-config-price.less';

export default ({ price, pricePrefix, productType }) => {
    return (
        <div>
            {pricePrefix && productType === 'cto' && (
                <Typography variant="body2" tag="span" className="config-price-prefix">
                    {pricePrefix}
                </Typography>
            )}
            <Typography variant="body2" tag="span" className="config-price strike-price">
                {price}
            </Typography>
        </div>
    );
};
