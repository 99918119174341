import React from 'react';

import withPageComponents from '../../../page/components/with-page-components';

/**
 * Renders placeholder content while the initial synchronous data is being loaded. The helps insure the lazy loading
 * happens better on client side routing
 */
export default withPageComponents(
    ({ productInitial, errors }) => {
        //remove placeholder with product initial is available or if page data as loaded with errors
        if (productInitial || (errors && errors.length > 0)) {
            return null;
        }
        return <div style={{ minHeight: '3000px' }}></div>;
    },
    { components: ['productInitial'], options: { withErrors: true } }
);
