import React from 'react';
import ProductStatusBadge from '../../../product/components/product-status-badge';

import withPageComponents from '../../../page/components/with-page-components';

const StockIndicator = ({ stockIndicatorProps, productInitial, showFor, device, shipDate }) => {
    if (!productInitial) return null;

    const { carepacktype, eol, product_type } = productInitial || {};

    if (showFor && !showFor.includes(device)) {
        return null;
    }

    return (
        carepacktype !== 'Electronic' &&
        !eol &&
        product_type !== 'CTO' && (
            <ProductStatusBadge
                product={productInitial}
                displayShipMessage={true}
                showPreOrderMsg={true}
                stockIndicatorProps={stockIndicatorProps}
                shipDate={shipDate}
            />
        )
    );
};

export default withPageComponents(StockIndicator, {
    components: ['productInitial'],
    device: true,
});
