import React from 'react';
import { SkeletonLoader } from '@hpstellar/core';
import { Typography } from '@hpstellar/core/visId';

import { Helpers } from '../../../../core';

import './sale-price-block.less';

const SubscriptionBlock = ({ subscriptionPrices, delimiter = 'or' }) => {
    if (!subscriptionPrices) return null;

    return subscriptionPrices.map(item => {
        const { salePrice, saleSubscriptionText, bgColor, translations } = item;
        const { salePriceSuffix } = translations || {};
        return (
            <>
                {salePrice && (
                    <span className={`subscription-price${saleSubscriptionText ? ' subscription-text-enabled' : ''}`}>
                        <Typography tag="span" variant="h5" weight={700} className="subscription-text">
                            {delimiter}
                        </Typography>
                        <span className={`subscription ${bgColor ? ` bg-${bgColor}` : ''}`}>
                            {saleSubscriptionText && (
                                <Typography tag="span" variant="caption" className="sale-subscription-text">
                                    {saleSubscriptionText}
                                </Typography>
                            )}
                            <span className="subscription-price-suffix">
                                <Typography tag="span" variant="h2" weight={700} className="sale-subscription-price">
                                    {Helpers.formatCurrency(salePrice, undefined, true)}
                                </Typography>
                                {salePriceSuffix && (
                                    <Typography tag="span" variant="h5" weight={700} className="subscription-text">
                                        {salePriceSuffix}
                                    </Typography>
                                )}
                            </span>
                        </span>
                    </span>
                )}
            </>
        );
    });
};

export default ({ salePrice, subscriptionPrices, salePriceSuffix, isLoading }) => {
    if (isLoading) {
        return <SkeletonLoader className="sale-subscription-price-block price-block-loader" />;
    }
    if (!salePrice) return null;

    return (
        <div className="sale-subscription-price-block">
            <Typography tag="span" variant="h2" weight={700} className="sale-subscription-price">
                {salePrice}
            </Typography>
            {salePriceSuffix && (
                <Typography tag="span" variant="h5" weight={700} className="subscription-text">
                    {salePriceSuffix}
                </Typography>
            )}
            {subscriptionPrices && <SubscriptionBlock subscriptionPrices={subscriptionPrices} />}
        </div>
    );
};
