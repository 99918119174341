import React from 'react';

import Specs from '../specs-section';
import CartSection from './cart-section';
import AddonSection from './addon-section';
import BadgeContainer from './badge-container';

export default ({}) => {
    return (
        <div className="hero-section v2">
            <CartSection />
            <Specs v2 />
            <BadgeContainer />
            <AddonSection />
        </div>
    );
};
