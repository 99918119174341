import React, { useState, useEffect } from 'react';
import { Button, Configurations, TextField, Typography } from '@hpstellar/core';

import withConfigurations from '../../product/components/product-configurations';
import ConfigurationDrawer from '../../ui/components/configuration-drawer';
import { Helpers } from '../../core/src/helpers';

const validateFormFields = (fromName, toName, recipientEmail, confirmEmail, isElectronic, translations) => {
    return {
        fromName: !fromName
            ? `${translations.fromName} is required`
            : !Helpers.isAlphaNumeric(fromName)
              ? `${translations.fromName} cannot contain spaces or special characters`
              : null,
        toName: !toName
            ? `${translations.toName} is required`
            : !Helpers.isAlphaNumeric(toName)
              ? `${translations.toName} cannot contain spaces or special characters`
              : null,
        recipientEmail:
            isElectronic && !Helpers.validateEmail(recipientEmail)
                ? `${translations.recipientEmail} is required`
                : null,
        confirmEmail:
            isElectronic && (!Helpers.validateEmail(confirmEmail) || confirmEmail !== recipientEmail)
                ? `Emails do not match`
                : null,
    };
};

const validateForm = (form, isElectronic, translations, setErrors, onConfirm) => () => {
    const { fromName, toName, recipientEmail, confirmEmail, message, productType } = form;
    const errors = validateFormFields(fromName, toName, recipientEmail, confirmEmail, isElectronic, translations);
    setErrors(errors);
    const noErrors = Object.keys(errors).reduce((r, path) => {
        const error = errors[path];
        return r && !error;
    }, true);
    noErrors &&
        onConfirm &&
        onConfirm({
            giftCardForm: {
                fromName,
                toName,
                recipientEmail,
                confirmEmail,
                message,
                type: productType,
                isGC: true,
            },
        });
};

const LABELS = {
    fromName: '',
};

const FormLabel = ({ label, info }) => (
    <div className="form-label">
        {label}
        {info && <span> {info}</span>}
    </div>
);

const onChange = (sku, setCTOConfiguration, isOpen, toggleDrawer) => value => {
    value && setCTOConfiguration(sku, { ...value });
    toggleDrawer(false);
};

const GiftCardForm = ({
    isElectronic,
    giftCardForm = {},
    onConfirm,
    productType,
    translations = {
        fromName: 'Your Name',
        toName: "Recipient's Name",
        recipientEmail: "Recipient's Email",
        confirmEmail: 'Confirm Email',
        message: 'Your message',
        confirmBtn: 'Confirm',
    },
}) => {
    const [fromName, setName] = useState(giftCardForm.fromName || '');
    const [toName, setToName] = useState(giftCardForm.toName || '');
    const [message, setMessage] = useState(giftCardForm.message || '');
    const [recipientEmail, setrecipientEmail] = useState(giftCardForm.recipientEmail || '');
    const [confirmEmail, setconfirmEmail] = useState(giftCardForm.confirmEmail || '');
    const [errors, setErrors] = useState({});
    const inputClassName = 'form-input';

    return (
        <>
            <FormLabel label="From" info="(Required)" />
            <TextField
                className={inputClassName}
                footNotePosition="left"
                fieldProps={{
                    name: 'input',
                    type: 'text',
                    onChange: e => setName(e.target.value),
                    defaultValue: fromName,
                }}
                label={translations.fromName}
                required
                validate
                error={errors['fromName']}
            />
            <FormLabel label="To" info="(Required)" />
            <TextField
                className={inputClassName}
                footNotePosition="left"
                fieldProps={{
                    name: 'input',
                    type: 'text',
                    onChange: e => setToName(e.target.value),
                    defaultValue: toName,
                }}
                label={translations.toName}
                required
                validate
                error={errors['toName']}
            />
            {isElectronic && (
                <>
                    <FormLabel label="Email" info="(Required)" />
                    <TextField
                        className={inputClassName}
                        footNotePosition="left"
                        fieldProps={{
                            name: 'input',
                            type: 'email',
                            onChange: e => setrecipientEmail(e.target.value),
                            defaultValue: recipientEmail,
                        }}
                        label={translations.recipientEmail}
                        required
                        validate
                        error={errors['recipientEmail']}
                    />
                    <TextField
                        className={inputClassName}
                        footNotePosition="left"
                        fieldProps={{
                            name: 'input',
                            type: 'email',
                            onChange: e => setconfirmEmail(e.target.value),
                            defaultValue: confirmEmail,
                        }}
                        label={translations.confirmEmail}
                        required
                        validate
                        error={errors['confirmEmail']}
                    />
                </>
            )}
            <FormLabel label="personalized message" info="(OPTIONAL)" />
            <TextField
                className={inputClassName}
                footNotePosition="left"
                multiline
                fieldProps={{
                    name: 'input',
                    type: 'text',
                    onChange: e => e.target.value && e.target.value.length <= 500 && setMessage(e.target.value),
                    defaultValue: message,
                    maxlength: 500,
                }}
                label={translations.message}
                footNote="Max 500 characters"
            />
            <Button
                variation="secondary"
                className="confirm-button"
                isFullWidth={true}
                onClick={validateForm(
                    { fromName, toName, recipientEmail, confirmEmail, message, productType },
                    isElectronic,
                    translations,
                    setErrors,
                    onConfirm,
                )}
            >
                {translations.confirmBtn}
            </Button>
        </>
    );
};

const GiftCardDetails = ({ giftCardForm }) => {
    const { fromName, toName, recipientEmail, message } = giftCardForm;
    return (
        <>
            <FormLabel label="From" />
            <Typography tag="p" variant="bodyRegular">
                {fromName}
            </Typography>
            <FormLabel label="To" />
            <Typography tag="p" variant="bodyRegular">
                {toName}
            </Typography>
            {recipientEmail && (
                <>
                    <FormLabel label="Email" />
                    <Typography tag="p" variant="bodyRegular">
                        {recipientEmail}
                    </Typography>
                </>
            )}
            {message && (
                <>
                    <FormLabel label="Message" />
                    <Typography tag="p" variant="bodyRegular">
                        {message}
                    </Typography>
                </>
            )}
        </>
    );
};

export const MessageForm = ({ content, action, label, requiredLabel }) => {
    return (
        <Configurations
            className="config-gift-card-message"
            requiredLabel={requiredLabel}
            label={label}
            content={content}
            action={action}
        />
    );
};

const ConfigureMessage = ({ product, setCTOConfiguration, drawerPosition = 'right', ctoConfig, Message }) => {
    const { sku, product_type } = product;
    const [isOpen, toggleDrawer] = useState(false);
    const isElectronic = product_type === 'VGC';
    const selectedOption = ctoConfig || {};
    useEffect(() => {
        const errorPath = 'giftCardForm';
        const errorMessage = ((selectedOption || {}).error || {})[errorPath];
        //if the forom hasn't been filled out yet and no error message has been set, set it
        if (!selectedOption || (!selectedOption.giftCardForm && !errorMessage)) {
            setCTOConfiguration(sku, { error: { [errorPath]: 'To | From required' } });
        } else if (selectedOption.giftCardForm && errorMessage) {
            //if the form is filled out and there is an error message, clear it
            setCTOConfiguration(sku, { error: { [errorPath]: undefined } });
        }
    }, [selectedOption.giftCardForm]);

    const { giftCardForm } = selectedOption;
    const content = giftCardForm ? <GiftCardDetails giftCardForm={giftCardForm} /> : 'From | To | Message';

    return (
        <>
            {Message ? (
                <Message details={content} onClick={() => toggleDrawer(!isOpen)} />
            ) : (
                <MessageForm
                    requiredLabel={'(Required)'}
                    label="Details"
                    content={content}
                    action={
                        <Button size={'small'} variation="tertiary" onClick={() => toggleDrawer(!isOpen)}>
                            Modify
                        </Button>
                    }
                />
            )}
            <ConfigurationDrawer
                isOpen={isOpen}
                onClose={() => toggleDrawer(false)}
                onChange={onChange(sku, setCTOConfiguration, isOpen, toggleDrawer)}
                drawerPosition={drawerPosition}
                modalClassName="gift-card-form-configure"
            >
                <GiftCardForm
                    onConfirm={onChange(sku, setCTOConfiguration, isOpen, toggleDrawer)}
                    giftCardForm={giftCardForm}
                    isElectronic={isElectronic}
                    productType={product_type}
                />
            </ConfigurationDrawer>
        </>
    );
};

export default withConfigurations(ConfigureMessage);
