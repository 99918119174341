import React from 'react';
import loadable from '@loadable/component';

import { Helpers } from '../../../core/src/helpers';

const PDPUpsell = loadable(() => Helpers.retryFunc(() => import('./upsell-container')));

import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
const SECTION_ID = 'similarProducts';

export const UpSellPlaceholder = ({ id = SECTION_ID }) => {
    return <section id={id}></section>;
};

/**
 * Lazy load container for upsell
 */
export default () => {
    return (
        <AboveTheFoldOnlyServerRender skip={true} offset={1000} robots={true} placeholder={<UpSellPlaceholder />}>
            <PDPUpsell id={SECTION_ID} />
        </AboveTheFoldOnlyServerRender>
    );
};
