import React, { useEffect } from 'react';

export default (
    ref,
    callback,
    options = {
        attributes: true,
        characterData: true,
        childList: true,
        subtree: true,
    }
) => {
    useEffect(() => {
        if (ref.current && callback) {
            const observer = new MutationObserver(() => {
                callback(ref.current);
            });
            observer.observe(ref.current, options);
            return () => observer.disconnect();
        }
    }, [callback, options]);
};
