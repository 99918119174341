import React from 'react';
import { SkeletonLoader } from '@hpstellar/core';

import withPageComponents from '../../page/components/with-page-components';
import VisualSocial from '../../third-party-tags/visual-social';
import { useBVSettings } from '../../hooks/useSiteConfig';

export const ugcContainerID = 'myHP';

export default withPageComponents(
    ({ productInitial, ugcAvailable }) => {
        const { social_gallery = true, sku } = productInitial || {};
        if (!social_gallery || !ugcAvailable) {
            return null;
        }

        const { transformedSKU } = useBVSettings(sku);
        const filter = `productId:'${transformedSKU}'`;
        const placeHolder = <SkeletonLoader height="520px" />;
        return (
            <div id={ugcContainerID}>
                <VisualSocial
                    containerID="product"
                    filter={filter}
                    placeHolder={placeHolder}
                    lazyLoadExperience={true}
                    renderOn="load"
                />
            </div>
        );
    },
    { components: ['productInitial', 'ugcAvailable'] }
);
