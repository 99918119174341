import React from 'react';

import { Helpers } from '../../../core/src/helpers';
import { LocalCache } from '../../../core/src/local-storage-cache';

import './facebook-messenger-banner.less';

const localCache = new LocalCache('msgai-subscribe', 90); 

class FacebookMessengerBanner extends React.PureComponent {
	constructor(props){
		super(props);
		this.state={
			subscribed: 'neverSubscribed',
		}
	}


	static defaultProps = {
		FB_MESSENGER_ID: '646916005814580',
		FB_PAGE_ID: '652616981903936'
    };

	componentDidMount = () => {
		const subscribed = localCache.get("subscribed");
		if (subscribed === true){
			this.setState({subscribed: "previouslySubscribed"});
		} else {
			this.initFb();
		}
	}

	initFb = () => {
		const { FB_MESSENGER_ID, prefix, omni, base } = this.props;
		window.fbAsyncInit =  () => {
			FB.init({
				appId: FB_MESSENGER_ID,
				autoLogAppEvents: true,
				xfbml: true,
				version: 'v6.0',
				status: true,
				cookie: true
			  });
			  
			  FB.Event.subscribe("send_to_messenger", (e) => {
				  //Event fired after CTA is clicked
				  if (e.event === 'clicked'){
					  Helpers.hpUtilTrackEvent({prefix, omni, base});
					  
				  } 
				  if (e.event === 'opt_in'){
					  //if user logs in
					  window.open('https://m.me/HPInkAssistant','_blank');
					  localCache.set("subscribed", true);
					  this.setState({ subscribed: 'justSubscribed'});
				  }
			  });
		  };
		  
		  (function(d, s, id) {
			  var js, fjs = d.getElementsByTagName(s)[0];
			  if (d.getElementById(id)) return;
			  js = d.createElement(s); js.id = id;
			  js.src = '//connect.facebook.net/en_US/sdk.js';
			  fjs.parentNode.insertBefore(js, fjs);
			}(document, 'script', 'facebook-jssdk'));
	}

	render() {

	const { FB_MESSENGER_ID, FB_PAGE_ID, imgsrc, header, detail, DATA_REF, variation } = this.props;
	const { subscribed } = this.state;
	if (subscribed === 'previouslySubscribed'){
		return null;
	} else {
			return (
				<div className={`fb-banner`}>
					<div className="banner-container">
						<img src={imgsrc} alt="facebook_img"></img>
						<div className={`content-container ${variation}`}>
							<h3>{header}</h3>
							<span className="fb-detail">{detail}</span>
                            <div className="fb-send-to-messenger" 
                                messenger_app_id={FB_MESSENGER_ID} 
                                page_id={FB_PAGE_ID} 
                                data-ref={DATA_REF} 
                                color="blue" 
                                size="xlarge"
                                cta_text="GET_STARTED"
                            >
                            </div>
						</div>
					</div>
					{subscribed === 'justSubscribed' && <div className="confirm-message">
						<p>
							Super! Now check Messenger to complete your sign up 
						</p>
					</div>}
				</div>
			)
		}
	}
}

export default FacebookMessengerBanner;