import React from 'react';
import { Typography } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import { Helpers } from '../../../core/src/helpers';

import './return-exchange-info.less';

const EMPTY_OBJECT = {};

const ReturnsAndExchange = ({ title = 'Return and exchange information', productInitial = EMPTY_OBJECT }) => {
    const { return_policy } = productInitial;
    let message =
        return_policy || 'HP.com will accept returns or exchanges for this product up to 30 days after delivery.';
    return (
        <div className="return-info">
            <Typography tag="h6" variant="titleS" className="return-title">
                {title}
            </Typography>
            <Typography tag="div" variant="bodyS" dangerouslySetInnerHTML={Helpers.createMarkup(message)} />
        </div>
    );
};

export default withPageComponents(ReturnsAndExchange, { components: ['productInitial'] });
