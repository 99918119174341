export function createObserver(elm, onChange) {
    try {
        let observer;

        let options = {
            root: null,
            rootMargin: '0px',
        };

        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                onChange && onChange(entry.intersectionRatio > 0);
            });
        }, options);
        observer.observe(elm);
        return {
            unobserve: () => {
                observer.unobserve(elm);
            },
        };
    } catch (e) {
        return { unobserve: () => {} };
    }
}
