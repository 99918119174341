import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import withPageComponents from '../../page/components/with-page-components';

/**
 * Custom scroll resetting for the PDP. Mainly to support the shadow CTO interaction
 */
export default withPageComponents(
    ({ vanityUrl, pdpShadowCTO, productInitial }) => {
        const { sku } = productInitial || {};
        const vanityUrlRef = useRef();
        const shadowCTORef = useRef();
        const location = useLocation();
        useEffect(() => {
            const { current: prevVanityUrl } = vanityUrlRef;
            const { current: prevShadowCTO } = shadowCTORef;
            const hasShadowCTO = pdpShadowCTO && pdpShadowCTO.shadowCTOEnabled;
            const prevHasShadowCTO = prevShadowCTO && prevShadowCTO.shadowCTOEnabled;
            const { hash } = location;
            //if the previous sku and the current sku both have shadowCTO set up. Don't reset the scroll position
            if ((!prevVanityUrl || prevVanityUrl !== vanityUrl) && !(prevHasShadowCTO && hasShadowCTO)) {
                setTimeout(() => {
                    const id = hash.replace('#', '');
                    const element = document.getElementById(id);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth' });
                    } else {
                        window.scrollTo(0, 0);
                    }
                }, 100);
            }
            vanityUrlRef.current = vanityUrl;
            shadowCTORef.current = pdpShadowCTO;
        }, [vanityUrl, pdpShadowCTO, location]);
        useEffect(() => {
            try {
                //store last visit sku in session storage
                sessionStorage.setItem('lastVisitSku', sku);
            } catch (e) {}
        }, [sku]);
        return null;
    },
    { components: ['pdpShadowCTO', 'productInitial'] }
);
