import React from 'react';
import { HpRewardBadge } from '@hpstellar/core';

import withPageComponents from '../../../../page/components/with-page-components';
import SustainabilityLogo from '../../../../product/components/sustainability-logo';
import './badge-container.less';

const EMPTY_OBJ = {};

const badgeContainer = ({ productInitial, pdpShadowCTO, hideRewards }) => {
    const { shadowCTOEnabled } = pdpShadowCTO || {};
    const { sustainability_logo_attribute, rewardsBadge } = productInitial || EMPTY_OBJ;
    const hasRewardsLabel = rewardsBadge && rewardsBadge.label && !hideRewards;

    if (!productInitial) {
        return null;
    }

    return (
        <div className="badge-container">
            <SustainabilityLogo sustainabilityAttribute={sustainability_logo_attribute} enableTooltip={true} />
            {hasRewardsLabel && !shadowCTOEnabled && (
                <HpRewardBadge
                    className="rewardsBadge"
                    message={rewardsBadge.label}
                    color={'purple'}
                    tooltipContent={{ title: rewardsBadge.tooltipTitle, description: rewardsBadge.tooltipBody }}
                />
            )}
        </div>
    );
};

export default withPageComponents(badgeContainer, {
    components: ['productInitial', 'pdpShadowCTO'],
});
