import React from 'react';

import { withError } from '../../../shared/components/error-boundary';
import { Helpers } from '../../../core/src/helpers';

import StartingAtBlock from './components/starting-at-price';
import SalePriceBlock from './components/sale-price-block';
import SelectedConfigPrice from './components/selected-config-price';
import DiscountPriceBlock from './components/discount-price-block';

import './price-block.less';

const getTierMsg = tierMsg => {
    //if tier msg exists return it with only the first letter capitalized
    if (tierMsg) {
        return tierMsg.charAt(0).toUpperCase() + tierMsg.toLowerCase().slice(1);
    }
};

const PriceBlock = ({
    regularPrice,
    salePrice,
    subscriptionPrices,
    translations,
    priceDifference,
    configPrice,
    productType,
    tierMsg,
    'aria-label': ariaLabel,
    customSaleText,
    violatorBgColor,
    isLoading,
}) => {
    const {
        saveMessage = 'you save',
        startingAt,
        configPricePrefix = 'Selected configuration',
        currency = '$',
        regularPrice: regularPricePrefix,
        savingsPrefix,
        separateSellingPrice,
        salePriceSuffix,
    } = translations || {};

    const priceDifferenceText = Helpers.formatCurrency(priceDifference, currency, true);
    const regularPriceText = Helpers.formatCurrency(regularPrice, currency, true);
    const salePriceText = Helpers.formatCurrency(salePrice, currency, true);
    const lowerCaseProductType = typeof productType === 'string' ? productType.toLowerCase() : '';
    const configRegularPriceText = Helpers.formatCurrency(configPrice?.regularPrice, currency, true);
    const configSalePriceText = Helpers.formatCurrency(configPrice?.salePrice, currency, true);
    const ctoPriceDifference = configPrice?.regularPrice - configPrice?.salePrice;
    const onSale = regularPrice > salePrice;
    const startingAtPrice = configPrice || onSale ? regularPriceText : '';
    const isBundle = lowerCaseProductType === 'bundle';
    const pricePrefix =
        lowerCaseProductType === 'cto' ? startingAt : isBundle ? separateSellingPrice : regularPricePrefix;
    return (
        <>
            <div className="price-block-variations" aria-label={ariaLabel}>
                <StartingAtBlock
                    tierMsg={getTierMsg(tierMsg)}
                    price={startingAtPrice}
                    pricePrefix={pricePrefix}
                    strikePrice={onSale && !configPrice && !isBundle}
                    isLoading={isLoading}
                />
                {configPrice && (
                    <SelectedConfigPrice
                        price={ctoPriceDifference > 0 && configRegularPriceText}
                        pricePrefix={configPricePrefix}
                        productType={lowerCaseProductType}
                    />
                )}
                <SalePriceBlock
                    salePrice={configPrice ? configSalePriceText : salePriceText}
                    salePriceSuffix={salePriceSuffix}
                    subscriptionPrices={subscriptionPrices}
                    isLoading={isLoading}
                />
                {priceDifference > 0 && (
                    <DiscountPriceBlock
                        violator={savingsPrefix}
                        violatorBgColor={violatorBgColor}
                        saveMessage={saveMessage}
                        priceDifference={priceDifferenceText}
                        customSaleText={customSaleText}
                    />
                )}
            </div>
        </>
    );
};

export default withError(PriceBlock);
