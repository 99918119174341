import React from 'react';
import loadable from '@loadable/component';

import { Helpers } from '../../../../core/src/helpers';
import withPageComponents from '../../../../page/components/with-page-components';
const RichContent = loadable(() => Helpers.retryFunc(() => import('./pdp-rich-content')));

const pdpOverview = ({ pdpRichContent, productInitial }) => {
    if (!productInitial || !pdpRichContent || !pdpRichContent.featuresContent) {
        return null;
    }
    return <RichContent pdpRichContent={pdpRichContent} productInitial={productInitial} />;
};

export default withPageComponents(pdpOverview, { components: ['pdpRichContent', 'productInitial'] });
