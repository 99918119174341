import React, { useEffect, useRef } from 'react';
import { Container } from '@hpstellar/core';

import useSiteConfig from '../../../hooks/useSiteConfig';

import withPageComponents from '../../../page/components/with-page-components';

const worldSyncContent = ({ productInitial }) => {
    const currentSku = useRef();
    const { enablePlaybookContent } = useSiteConfig();
    useEffect(() => {
        const { sku, upc } = productInitial;
        if (enablePlaybookContent && sku && currentSku.current !== sku) {
            window.ccs_cc_args = window.ccs_cc_args || [];

            // HP Shop US Product Page auto-insert all the below from your database

            ccs_cc_args.push(['mf', 'HP']);
            ccs_cc_args.push(['pn', sku]);
            ccs_cc_args.push(['upcean', upc]);
            ccs_cc_args.push(['lang', 'EN']);
            ccs_cc_args.push(['market', 'US']);

            (function () {
                var o = ccs_cc_args;
                o.push(['_SKey', '34f84864']);
                o.push(['_ZoneId', '5322de89b3']);
                var sc = document.createElement('script');
                sc.type = 'text/javascript';
                sc.async = true;
                sc.src =
                    ('https:' == document.location.protocol ? 'https://' : 'http://') +
                    'cdn.cs.1worldsync.com/jsc/h1ws.js';
                var n = document.getElementsByTagName('script')[0];
                n.parentNode.insertBefore(sc, n);
            })();
        }
        currentSku.current = sku;
    }, [productInitial]);
    if (!enablePlaybookContent) {
        return null;
    }
    return (
        <Container>
            <div id="ccs-inline-content"></div>
        </Container>
    );
};

export default withPageComponents(worldSyncContent, { components: ['productInitial'] });
