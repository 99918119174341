import React from 'react';

import { Typography } from '@hpstellar/core';
import { ShippingChecked } from '@hpstellar/icons';

import './delivery-message.less';

export default ({ message, size, isFree, icon, enableIcon = true, freeShippingMessage = 'Free Shipping' }) => {
    if (!message) return null;
    const iconElm = icon || <ShippingChecked />;
    return (
        <div className={`delivery-message${size ? ` delivery-message-${size}` : ''}${isFree ? ` free-delivery` : ''}`}>
            {enableIcon && (
                <span className="delivery-prefix">
                    {iconElm}
                    {isFree ? <Typography variant="boldS">{freeShippingMessage}</Typography> : null}
                </span>
            )}
            <Typography variant="bodyS">{message}</Typography>
        </div>
    );
};
