import React from 'react';

import withPageComponents from '../../../page/components/with-page-components';
import GiftCard from '../../../gift-card/gift-card-v2';

import CompareCheckbox from '../../../compare/components/compare-checkbox';
import useProductPrice from '../../../hooks/useProductPrice';
import useProductImpressions from '../../../hooks/useProductImpressionsV2';
import DeliveryMessage from './pdp-delivery-message';
import ConsumerFinanceWidget from '../../../product/components/consumer-finance-widget';

import { onEnterClick } from '../../../ui/components/accessible-element';
import PreOrderDisclaimer from './pre-order-disclaimer-link';
import FacebookChatbot from './facebook-chatbot';
import CarePackModule from '../carepacks';
import CarePackMessage from './ecare-pack-message';
import { CompatibleProductsLink } from '../compatible-products';
import StockIndicator from './stock-indicator';
import CTOOptions from '../cto-options';
import PriceBlock from './price-block';
import CTAWrapper from './cta';
import './cart-section.less';

const getCompareProduct = (productInitial, images = {}, prices) => {
    const imageUrl =
        images?.smallImages?.[0]?.url ||
        images?.mediumImages?.[0]?.url ||
        images?.fullImages?.[0]?.url ||
        images?.thumbnailImages?.[0]?.url;

    return {
        ...productInitial,
        title: productInitial.name,
        images: [{ src: imageUrl }],
        price: prices[productInitial.sku],
    };
};

const cartSection = ({ productInitial, productInitialPrice, pdpImages, pdpCarePackBundle, pdpShadowCTO }) => {
    const { enrollmentKit, shipDate } = productInitial || {};
    const { carepacks } = pdpCarePackBundle || {};
    const { prices } = useProductPrice([productInitial, ...(Array.isArray(carepacks) ? carepacks : [])]);
    const { plcode, eol, sku } = productInitial || {};
    const priceObj = (prices || {})[sku];
    const { backOrderFlag, preOrder } = priceObj || {};
    const { shadowCTOEnabled } = pdpShadowCTO || {};
    const hideStockMessage = preOrder || backOrderFlag;

    const productsWithAnalytics = useProductImpressions({
        products: productInitial && [productInitial],
        list: 'pdp',
        componentKey: 'productInitial',
        key: sku,
        isMainSku: true,
        // nonImpression: true,
    });

    const prdInitial = (Array.isArray(productsWithAnalytics) && productsWithAnalytics[0]) || productInitial;

    if (!prdInitial) return null;
    const displaySKU = (sku || '').replace(/^P_/, '');

    return (
        <div id="addtocart" className={'pdp-cart-section'}>
            <CompatibleProductsLink />
            <GiftCard product={prdInitial} />
            {!shadowCTOEnabled && <PriceBlock className="pdp-price-row" hidePrice={!!enrollmentKit} />}
            {!shadowCTOEnabled && (
                <ConsumerFinanceWidget product={prdInitial} price={priceObj || productInitialPrice} variant="section" />
            )}
            <CTOOptions />
            {/* display care pack module above CTA for mobile only */}
            <CarePackModule showFor={['mobile']} />
            <PreOrderDisclaimer priceObj={priceObj} />
            {backOrderFlag && !hideStockMessage && <StockIndicator showFor={['mobile']} />}
            <CTAWrapper hideForShadowCTO={true} />
            {!shadowCTOEnabled && <DeliveryMessage />}
            {backOrderFlag && !hideStockMessage && (
                <StockIndicator showFor={['tablet', 'desktop']} shipDate={shipDate} />
            )}
            <CarePackModule showFor={['tablet', 'desktop']} />
            {!backOrderFlag && !hideStockMessage && <StockIndicator shipDate={shipDate} />}
            <CarePackMessage />
            <div className="compare-sku" onKeyUp={onEnterClick(null, true)}>
                {!eol && (
                    <CompareCheckbox
                        product={getCompareProduct(prdInitial, pdpImages, prices)}
                        btnText={<h4>Add to compare</h4>}
                        selectedText={<h4>Selected to compare</h4>}
                    />
                )}
                <span className="sku">Product # {displaySKU}</span>
            </div>
            <FacebookChatbot plcode={plcode} />
        </div>
    );
};

cartSection.whyDidYouRender = true;

export default withPageComponents(cartSection, {
    components: ['productInitial', 'productInitialPrice', 'pdpImages', 'pdpCarePackBundle', 'pdpShadowCTO'],
});
