import React from 'react';

import PDPGallery from './pdp-gallery';
import Offers from './above-the-fold-offers';
import TitleSection from './title-section';
import ProductDetailsContainer from './pdp-detail-container';
import StickyWrapper from './sticky-wrapper';
import ShadowHeader from '../shadow-header';
import HeroSection from './hero-section';
import HeroSectionV2 from './product-hero-container';
import useTestFlags from '../../../abtest/useTestFlags';
import withPageComponents from '../../../page/components/with-page-components';

const pdpDetails = ({ productInitial, pdpShadowCTO, templateKey }) => {
    const { pdpPriceBlock = {} } = useTestFlags(['pdpPriceBlock']);
    const { pdpABTest } = pdpPriceBlock;
    const { priceEnabled } = pdpABTest || {};
    const { shadowCTOEnabled } = pdpShadowCTO || {};
    const pdpTestEnabled = priceEnabled && !productInitial?.enrollmentKit;
    return (
        <div style={{ position: 'relative' }}>
            <ShadowHeader v2={pdpTestEnabled} templateKey={templateKey} />
            <ProductDetailsContainer>
                <div className={`image-section${pdpTestEnabled ? ' v2' : ''}`}>
                    {!shadowCTOEnabled && (
                        <TitleSection showFor={!pdpTestEnabled ? ['mobile'] : undefined} v2={pdpTestEnabled} />
                    )}
                    <StickyWrapper>
                        <PDPGallery />
                        <Offers showFor={['desktop', 'tablet']} />
                    </StickyWrapper>
                </div>
                {!pdpTestEnabled ? <HeroSection /> : <HeroSectionV2 templateKey={templateKey} />}
            </ProductDetailsContainer>
            <Offers showFor={['mobile']} className="pdp-mobile-offers" />
        </div>
    );
};
export default withPageComponents(pdpDetails, { components: ['pdpShadowCTO', 'productInitial'] });
