import React, { useState } from 'react';
import loadable from '@loadable/component';

import withPageComponents from '../../../page/components/with-page-components';
import useProductPrice from '../../../hooks/useProductPrice';
import { Helpers } from '../../../core/src/helpers';

const OOSModal = loadable(() => Helpers.retryFunc(() => import('./oos-modal')));

const handleModalClose = (isOpen, setIsOpen) => () => {
    setIsOpen(!isOpen);
};

/**
 * mimic current production where supplies type skus are excluded
 * @param {*} productInitial
 * @returns
 */
const excludePopup = productInitial => {
    const { pm_producttype } = productInitial || {};
    return ['ink/toner/paper/printer supplies'].includes((pm_producttype || '').toLowerCase());
};

const getProductArray = productInitial => {
    const { shadowCTOSkus, sku: currentSku } = productInitial || {};
    const skuList = Object.keys(shadowCTOSkus || {});
    try {
        if (skuList.length > 0) {
            return [
                productInitial,
                ...skuList.reduce((r, sku) => {
                    const { catentryId } = shadowCTOSkus[sku];
                    //include all other shadow sku skus in price call
                    if (currentSku !== sku) {
                        r.push({
                            sku,
                            catentryId,
                        });
                    }
                    return r;
                }, []),
            ];
        }
    } catch (e) {}
    return [productInitial];
};

const allOptionsOOS = prices => {
    return Object.keys(prices).reduce((r, sku) => {
        const { isOOS } = prices[sku];
        return r && isOOS;
    }, true);
};

const oosPopup = ({ pdpSimilarProducts, productInitial }) => {
    const [isOpen, setIsOpen] = useState(true);
    const { products } = pdpSimilarProducts || {};
    const { sku } = productInitial || {};
    const { prices } = useProductPrice(getProductArray(productInitial));
    const priceObj = prices[sku];
    if (!priceObj || !allOptionsOOS(prices) || excludePopup(productInitial) || priceObj.isComingSoonSku) {
        return null;
    }
    return (
        <OOSModal
            sku={sku}
            similarProducts={products}
            isOpen={isOpen}
            onModalClose={handleModalClose(isOpen, setIsOpen)}
        />
    );
};

export default withPageComponents(oosPopup, { components: ['productInitial', 'pdpSimilarProducts'] });
