import React from 'react';
import { SkeletonLoader } from '@hpstellar/core';
import { Typography } from '@hpstellar/core/visId';

import './starting-at-price.less';

export default ({ price, pricePrefix, strikePrice, tierMsg, isLoading }) => {
    if (isLoading) {
        return <SkeletonLoader className="starting-at-price price-block-loader" />;
    }
    return (
        <div className={`starting-at-price`}>
            {tierMsg && (
                <Typography variant="body1" className="tier-msg" tag="p">
                    {tierMsg}
                </Typography>
            )}
            {pricePrefix && (
                <Typography variant="body1" tag="span" className="price-prefix">
                    {pricePrefix}&nbsp;
                </Typography>
            )}
            {price && (
                <Typography variant="body1" tag="span" className={`price${strikePrice ? ' strike-price' : ''}`}>
                    {price}
                </Typography>
            )}
        </div>
    );
};
