import React from 'react';
import loadable from '@loadable/component';

import withPageComponents from '../../../page/components/with-page-components';

const ShadowHeaderContainer = loadable(() => import('./shadow-header-container'));

const ShadowHeader = ({ pdpShadowCTO, productInitial, v2 }) => {
    const { shadowCTOEnabled } = pdpShadowCTO || {};
    if (!shadowCTOEnabled) {
        return null;
    }

    return <ShadowHeaderContainer pdpShadowCTO={pdpShadowCTO} productInitial={productInitial} v2={v2} />;
};

export default withPageComponents(ShadowHeader, { components: ['pdpShadowCTO', 'productInitial'] });
