import React from 'react';

import ABTest from '../../../abtest/components/abtest';
import withPageComponents from '../../../page/components/with-page-components';
import FBMessengerBanner from '../../../shared/components/facebook-messenger';
import { Helpers } from '../../../core/src/helpers';

export default withPageComponents(({ device, plcode }) => {
    const isMobileView = device === 'mobile';
    return <ABTest experimentID="18248691377" timeout={0}>
        {(data={}) => {
            const { prefix, omni, base, variation, render } = data;
            const subCat = plcode && Helpers.getMappedSubCat(plcode);
            return subCat === 'ink' && render && variation ?
                <FBMessengerBanner
                    DATA_REF='U2FsdGVkX18v4Il1/rckUlQ6CZhy/4/FTWFtGBZBe+c='
                    prefix={prefix}
                    omni={omni}
                    base={base}
                    variation={variation}
                    imgsrc={!isMobileView && variation && variation === 'var1' ? null : 'https://www.hp.com/us-en/shop/app/assets/images/uploads/prod/low-ink-large1585101442757431.png'}
                    header='Need a reminder?'
                    detail='Be notified on Facebook Messenger the next time your HP inkjet printer runs low on ink.'
                /> : null;
        }}
    </ABTest>
},{device: true});