import React from 'react';
import { OfferBlock, Link } from '@hpstellar/core';
import { useSmoothScroll } from '@hpstellar/core/hooks';
import { Helpers } from '../../../core/src/helpers';

import withPageComponents from '../../../page/components/with-page-components';

const handleClick = scrollToTarget => () => {
    try {
        scrollToTarget(document.getElementById('specialOffers'));
    } catch (e) {}
};

const abfOffers = ({ pdpSpecialOffers, pdpPromoBanner, device, showFor = [], className }) => {
    const { scrollToTarget } = useSmoothScroll({ offset: 100 });
    const offerClassName = 'pdp-details-offers';
    if (!showFor.includes(device) || (!pdpSpecialOffers?.length && !pdpPromoBanner)) {
        return null;
    }

    if (pdpPromoBanner) {
        return (
            <div
                className={`${offerClassName} custom-banner${className ? ' ' + className : ''}`}
                dangerouslySetInnerHTML={Helpers.createMarkup(pdpPromoBanner)}
            />
        );
    }

    const offerCount = pdpSpecialOffers.length;
    const [firstOffer] = pdpSpecialOffers;
    const { anchorLinkText, gtmActions } = firstOffer || {};
    let offerText = `${pdpSpecialOffers
        .slice(0, 3)
        .map(o => o.title)
        .join(' | ')}`;
    const renderLearnMore = (styles, gtmPromotionClickAttrs) => {
        return (
            <Link
                aria-label={anchorLinkText || 'Learn more'}
                color="dark"
                onClick={handleClick(scrollToTarget)}
                variation="primary"
                {...gtmPromotionClickAttrs}
            >
                {anchorLinkText || 'Learn more'}
            </Link>
        );
    };

    return (
        <div className={`${offerClassName}${className ? ' ' + className : ''}`}>
            <OfferBlock
                body={offerText && <span dangerouslySetInnerHTML={Helpers.createMarkup(offerText)} />}
                renderLearnMore={renderLearnMore}
                title="Special Offers"
                gtmActions={gtmActions}
            />
        </div>
    );
};

export default withPageComponents(abfOffers, { components: ['pdpSpecialOffers', 'pdpPromoBanner'], device: true });
