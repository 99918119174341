import React from 'react';
import { Features } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import { formatMetricValue } from '../../../metrics/metrics-helpers';
import { Helpers } from '../../../core/src/helpers';

const exclusiveFeatures = ({
    exclusiveFeatures,
    category,
    expandBtnText = 'See more features',
    collapseBtnText = 'See less features',
}) => {
    if (exclusiveFeatures) {
        return (
            <div className="pdp-features">
                <Features
                    id="features"
                    features={exclusiveFeatures.map(f => ({
                        ...f,
                        description: <span dangerouslySetInnerHTML={Helpers.createMarkup(f.description)} />,
                    }))}
                    theme={category}
                    gtmActions={
                        new Map([
                            [
                                'expandAction',
                                {
                                    gtmCategory: 'linkClick',
                                    gtmId: 'product',
                                    gtmValue: formatMetricValue(expandBtnText),
                                },
                            ],
                        ])
                    }
                    accordionProps={{ size: 'small' }}
                    translations={{
                        Features: {
                            collapseBtnText: collapseBtnText,
                            expandBtnText: expandBtnText,
                            mobileExpandBtnText: 'Features',
                        },
                    }}
                />
            </div>
        );
    }
    return null;
};

export default withPageComponents(exclusiveFeatures, { components: ['exclusiveFeatures'] });
