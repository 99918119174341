import React from 'react';
import { Button, Link } from '@hpstellar/core';

import { Helpers } from '../../../../core';

export const getEnrollmentKitPriceProps = kitDisplayPrice => {
    return kitDisplayPrice
        ? [
              {
                  ...(kitDisplayPrice.isSubscription
                      ? { saleSubscriptionText: 'Subscribe for ', bgColor: 'blue' }
                      : {}),
                  ...kitDisplayPrice,
              },
          ]
        : [];
};

const getPriceText = kitDisplayPrice => {
    const { salePriceSuffix } = kitDisplayPrice.translations || {};
    return `${Helpers.formatCurrency(kitDisplayPrice.salePrice, undefined, true)}${salePriceSuffix}`;
};

export default props => {
    const { btnProps, enrollmentKitProps, enableKitAddToCart, learnMoreLink, kitDisplayPrice } = props;
    if (!enrollmentKitProps) {
        return null;
    }
    const ctaProps = {
        ...btnProps,
        variation: 'primary',
        isFullWidth: true,
        children: kitDisplayPrice ? `${btnProps.children} from ${getPriceText(kitDisplayPrice)}` : btnProps.children,
    };

    return (
        <div className="cta-kit">
            <Button {...ctaProps} {...enrollmentKitProps} />
            {enableKitAddToCart && learnMoreLink && (
                <div>
                    <Link to={learnMoreLink} variation="body" weight="light">
                        Learn more about Instant Ink Enrollment Kit
                    </Link>
                </div>
            )}
        </div>
    );
};
