import React from 'react';
import { Button, Typography } from '@hpstellar/core/visId';

import GiftCard, { isGiftCard } from '../../../../gift-card/gift-card-v2';
import { MessageForm } from '../../../../gift-card/gift-card-v2/configure-message';
import withPageComponents from '../../../../page/components/with-page-components';
import PriceBlock from '../../price-block';
import { useGetProductPrice } from '../../../../hooks/useProductPrice';
import './gift-card-container.less';

const Amount = ({ price = 0, onClick, title = 'Amount (Required):', configureText = 'Modify', product }) => {
    const priceObj = useGetProductPrice(product);
    const giftCardPrice = price > 0 ? { regularPrice: price, salePrice: price } : {};
    return (
        <>
            <Typography variant="body1" className="title">
                {title}
            </Typography>
            <div className="price-modify-wrapper">
                <PriceBlock {...(priceObj || {})} {...giftCardPrice} />
                <Button theme="accent" variation="tertiary" onClick={onClick}>
                    {configureText}
                </Button>
            </div>
        </>
    );
};

const Message = ({
    details,
    onClick,
    title = 'Details (Required):',
    configureText = 'Modify',
    subTitle = 'From | To | Message',
}) => {
    return (
        <>
            <Typography variant="body1" className="title">
                {title}
            </Typography>
            <div className="msg-modify-wrapper">
                <Typography variant="body2">{subTitle}</Typography>
                <Button className="msg-modify-btn" theme="accent" variation="tertiary" onClick={onClick}>
                    {configureText}
                </Button>
            </div>
            {details && typeof details !== 'string' && <MessageForm content={details} />}
        </>
    );
};

export default withPageComponents(
    ({ productInitial, children }) => {
        return isGiftCard(productInitial) ? (
            <div className="gift-card-container">
                <GiftCard product={productInitial} Amount={Amount} Message={Message} />
            </div>
        ) : (
            children
        );
    },
    { components: ['productInitial'] },
);
