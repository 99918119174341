import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { setActiveTab } from '../page/components/disclaimer-tabs/disclaimer-store';

export default function useDisclaimerTabs() {
    const dispatch = useDispatch();
    return [useSelector(state => state.disclaimer), tab => dispatch(setActiveTab(tab))];
}
