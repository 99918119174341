import React, { useState } from 'react';

import { ChevronDown, ChevronUp } from '@hpstellar/icons';

import './accordion.less';

export default function Accordion({ title, description }) {
    const [active, setActive] = useState(false);

    return (
        <div className="simpleAccordion" onClick={() => setActive(!active)}>
            <div className="title">
                <span>{title}</span> {active ? <ChevronUp size="s" /> : <ChevronDown size="s" />}
            </div>
            {active && <div className="description">{description}</div>}
        </div>
    );
}
