import React from 'react';

import useProductPrice from '../../../hooks/useProductPrice';
import { Helpers } from '../../../core/src/helpers';

export const getSubscriptionTermsHTML = priceObj => {
    const { subscriptionDescription, stoSKUType } = priceObj || {};
    if (stoSKUType !== 'SUBSCRIPTION' || !subscriptionDescription) {
        return null;
    }
    return `<div class="row subsLegal"><style>.dProdPrice{display: inline-block;padding: 0; }.dProdPrice sup{top: 0px; position: relative; vertical-align: baseline; font-size: 100%;}.subsLegal{margin: 15px auto auto !important; line-height: 24px; border: 1px dashed #ccc; padding: 20px; background-color: #fbfbfb;}.subsLegal a{color:#0171ad}.subsLegal a:hover{text-decoration: underline}</style>${Helpers.decodeHtml(
        subscriptionDescription
    )}</div>`;
};

export default ({ product }) => {
    const { prices = {} } = useProductPrice([product]);
    const priceObj = prices[(product || {}).sku];
    const subTermsHtml = getSubscriptionTermsHTML(priceObj);
    if (!subTermsHtml) {
        return null;
    }
    return <div dangerouslySetInnerHTML={Helpers.createMarkup(subTermsHtml)} />;
};
