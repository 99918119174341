import React from 'react';

import withPageComponents from '../../page/components/with-page-components';
import JsonLd from '../../shared/components/json-ld';
import { getProductJsonLdImageArrayData, getProductJsonLdData } from '../../product/components/product-json-ld';

export default withPageComponents(
    ({ data, renderInHead, productInitial: product, pdpImages, productInitialPrice: price, seo }) => {
        const schemaData = [...(data || [])];
        let parsedImages = getProductJsonLdImageArrayData(pdpImages?.mediumImages);
        let { image } = parsedImages || {};
        if (product && product.name) {
            schemaData.push({ type: 'product', data: getProductJsonLdData({ ...product, image, price }, seo) });
        }

        return schemaData.length > 0 ? (
            <JsonLd data={schemaData} shouldNotUseDefaults={true} renderInHead={renderInHead} />
        ) : null;
    },
    { components: ['productInitial', 'pdpImages', 'productInitialPrice'], seo: true }
);
