import React from 'react';

import withPageComponents from '../../page/components/with-page-components';
import SeoMeta from '../../page/components/seo-meta';
import { getPDPTitle, getProductDescription } from '../seo-helpers';

const mapSEOData = (product, seo) => {
    const title = getPDPTitle(product);
    const description = getProductDescription(product);
    const keywords = seo.keywords;
    return {
        title,
        description,
        ...seo,
        keywords,
        canonical: seo.canonical || product.linkUrl,
    };
};

export default withPageComponents(
    ({ productInitial, seo, url }) => {
        if (productInitial) {
            return <SeoMeta seo={mapSEOData(productInitial, seo || {}, url)} />;
        }
        return null;
    },
    { components: ['productInitial'] }
);
