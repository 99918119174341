import React from 'react';
import { Link } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';

const CarePackMessage = ({
    productInitial,
    message = 'HP Care Packs are delivered electronically',
    ctaText = 'SEE DETAILS',
    ctaLink = 'https://www.hp.com/us-en/shop/cv/carepackfaq',
}) => {
    if (!productInitial || productInitial.carepacktype !== 'Electronic') {
        return null;
    }
    return (
        <div className="ecarepack-message">
            {message}
            <Link to={ctaLink} target="_blank">
                {ctaText}
            </Link>
        </div>
    );
};

export default withPageComponents(CarePackMessage, { components: ['productInitial'] });
