import React from 'react';

import AboveTheFoldOnlyServerRender from '../../shared/components/above-the-fold-only-server-render';

import PDPNav from './sticky-nav';
import PDPDetails from './pdp-details';
import PDPOverview from './pdp-overview';
import Features from './pdp-overview/exclusive-features';
import RichContent from './pdp-overview/pdp-rich-content';
import PDPSpecs from './pdp-specs';
import PDPOffers from './pdp-offers';
import Reviews from './reviews';
import PDPCrossSell from './cross-sell';
import PDPUpSell from './upsell';
import CompatibleProducts from './compatible-products';
import ReturnsAndExchange from './returns-exchange-info';
import OOSPopup from './oos-popup';
import LoadingPlaceholder from './loading-placeholder';
import FAQS from './faqs';
import ResetScrollPosition from './reset-scroll-position';
import UGC from './ugc';

import './pdp.less';

const DEFAULT_LAZY_LOAD_SETTINGS = {
    skip: true,
    robots: true,
};

const PDP = () => {
    return (
        <>
            <ResetScrollPosition />
            <LoadingPlaceholder />
            <PDPDetails />
            <PDPNav />
            <div id="nav-boundary"></div>
            <AboveTheFoldOnlyServerRender skip={true} robots={true}>
                <PDPOverview />
            </AboveTheFoldOnlyServerRender>
            <AboveTheFoldOnlyServerRender skip={true} robots={true}>
                <CompatibleProducts />
            </AboveTheFoldOnlyServerRender>
            <PDPCrossSell />
            <RichContent />
            <Features />
            <PDPSpecs lazyLoadSettings={DEFAULT_LAZY_LOAD_SETTINGS} />
            <PDPOffers />
            <FAQS lazyLoadSettings={DEFAULT_LAZY_LOAD_SETTINGS} />
            <UGC />
            <Reviews />
            <PDPUpSell />
            <AboveTheFoldOnlyServerRender skip={true} renderOn="load" robots={true}>
                <ReturnsAndExchange />
            </AboveTheFoldOnlyServerRender>

            <AboveTheFoldOnlyServerRender skip={true} renderOn="load">
                <OOSPopup />
            </AboveTheFoldOnlyServerRender>
        </>
    );
};

export default PDP;
