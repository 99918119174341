import React, { useEffect, useState } from 'react';

// give up on etilize content after 2 seconds
const MAX_ATTEMPTS = 10;
const POLLING_INTERVAL = 200;

const pollForEtilizeContent =
    (setNoContent, scriptId, maxAttempts = MAX_ATTEMPTS, interval = POLLING_INTERVAL) =>
    () => {
        let attempts = 0;
        let pollingInternval = setInterval(function () {
            attempts += 1;
            //if script tag is gone then etilize has been set
            if (!document.getElementById(scriptId)) {
                clearInterval(pollingInternval);
            }
            if (attempts > maxAttempts) {
                clearInterval(pollingInternval);
                //if script tag is still there after the max intervals then assume no content
                if (document.getElementById(scriptId)) {
                    setNoContent(true);
                }
            }
        }, interval);
    };

/***
 * children will used as fallback content if etilize does not inject their own
 */
export default React.memo(
    ({
        containerId = 'spexWidget',
        oid,
        authKey = 'adec16e4f515358bcbfdaa16dd443248',
        target = 'HP',
        profileId = '439',
        children,
        style = {},
    }) => {
        const [noContent, setNoContent] = useState(false);
        useEffect(() => {
            let script;
            if (oid) {
                script = document.createElement('script');
                script.src = '//content.etilize.com/apps/spexaccess/spex-widget/spexWidget.js';
                script.async = true;
                script.id = 'spexWidgetjs';
                script.setAttribute('data-spex-target', target);
                script.setAttribute('data-spex-auth-key', authKey);
                script.setAttribute('data-spex-profile-id', profileId);
                script.setAttribute('data-spex-only-enhanced-content', 'true');
                script.setAttribute('data-spex-locale', 'en_us');
                script.setAttribute('data-spex-etilize-product-id', oid);
                script.onload = pollForEtilizeContent(setNoContent, script.id);

                document.getElementById(containerId).appendChild(script);
            }
            return () => {
                script && document.body.removeChild(script);
            };
        }, [oid]);
        return (
            <div id={containerId} style={style}>
                {noContent && children}
            </div>
        );
    }
);
