import React from 'react';
import loadable from '@loadable/component';

import withPageComponents from '../../../page/components/with-page-components';
import useProductPrice from '../../../hooks/useProductPrice';

const FAQContainer = loadable(() => import('./faq-container'));

const DEFAULT_ACCORDION_PROPS = {
    iconType: 'caret',
    size: 'small',
    isStatic: true,
    className: 'faq-section-accordion',
};

const pdpFAQs = ({ productInitial, accordionProps = DEFAULT_ACCORDION_PROPS }) => {
    const { sku } = productInitial || {};
    const { prices } = useProductPrice(productInitial ? [productInitial] : []);
    const productPrice = prices[sku];
    const { preOrder } = productPrice || {};

    return productInitial && preOrder ? <FAQContainer accordionProps={accordionProps} /> : null;
};

export default withPageComponents(pdpFAQs, { components: ['productInitial'] });
