import React, { useState, useEffect } from 'react';
import { Button, Configurations } from '@hpstellar/core';

import withConfigurations from '../../product/components/product-configurations';
import ConfigurationDrawer from '../../ui/components/configuration-drawer';
import useProductPrice from '../../hooks/useProductPrice';
import { Helpers } from '../../core/src/helpers';

const onChange = (sku, setCTOConfiguration, isOpen, toggleDrawer, items) => value => {
    toggleDrawer(false);
    const item = items[value];
    const { salePrice, regularPrice, itemId } = item;
    setCTOConfiguration(sku, { configCatentryId: itemId, price: { salePrice, regularPrice } });
};

const setDefaultConfiguration = (sku, setCTOConfiguration, items, configurations) => {
    try {
        const item = items[configurations[0].value];
        const { salePrice, regularPrice, itemId } = item;
        setCTOConfiguration(sku, { configCatentryId: itemId, price: { salePrice, regularPrice } });
    } catch (e) {}
};

/**
 * Loops through gift card items and create configuration options
 * @param {*} items
 * @param {*} selectedItem
 * @returns
 */
export const getConfigurations = (items, selectedItem) => {
    return Object.keys(items || {})
        .reduce((r, v, i) => {
            const item = items[v];
            const { itemId, salePrice, order } = item;
            if (!!order) {
                r.push({
                    label: Helpers.formatCurrency(salePrice, '$', true, true),
                    checked: i === 0,
                    value: itemId,
                    checked: selectedItem ? itemId === selectedItem.configCatentryId : false,
                    order,
                });
            }
            return r;
        }, [])
        .sort((s1, s2) => s1.order * 1 - s2.order * 1);
};

const ConfigureAmount = ({
    product,
    setCTOConfiguration,
    drawerPosition = 'right',
    ctoConfig,
    Amount,
    selectDefault,
}) => {
    const { sku } = product;
    const { prices } = useProductPrice([product]);
    const [isOpen, toggleDrawer] = useState(false);
    const { items } = prices[sku] || {};
    const itemCount = Object.keys(items || {}).length;
    const selectedOption = ctoConfig || {};
    const { configCatentryId } = selectedOption || {};
    const configurations = getConfigurations(items, selectedOption);
    useEffect(() => {
        if (sku && itemCount > 0 && selectDefault && !configCatentryId)
            setDefaultConfiguration(sku, setCTOConfiguration, items, configurations);
    }, [sku, itemCount, selectDefault, configCatentryId]);
    useEffect(() => {
        const errorPath = 'amount';
        const errorMessage = ((selectedOption || {}).error || {})[errorPath];
        //if an item hasn't been selected yet and no error message has been set, set it
        if (!selectedOption || (!selectedOption.configCatentryId && !errorMessage)) {
            setCTOConfiguration(sku, { error: { [errorPath]: 'Amount required' } });
        } else if (selectedOption.configCatentryId && errorMessage) {
            //if the amount has been selected and there is an error message, clear it
            setCTOConfiguration(sku, { error: { [errorPath]: undefined } });
        }
    }, [selectedOption.configCatentryId]);
    const content =
        selectedOption && selectedOption.price
            ? Helpers.formatCurrency(selectedOption.price.salePrice, '$', true, true)
            : 'Select One';
    return (
        <>
            {Amount ? (
                <Amount
                    price={selectedOption?.price?.salePrice}
                    onClick={() => toggleDrawer(!isOpen)}
                    product={product}
                />
            ) : (
                <Configurations
                    requiredLabel={'(Required)'}
                    label="Amount"
                    content={content}
                    action={
                        <Button size={'small'} variation="tertiary" onClick={() => toggleDrawer(!isOpen)}>
                            Modify
                        </Button>
                    }
                />
            )}
            <ConfigurationDrawer
                isOpen={isOpen}
                title="Choose Your Amount"
                onClose={() => toggleDrawer(false)}
                configurations={configurations}
                subheader={`Amount (${configurations.length})`}
                onChange={onChange(sku, setCTOConfiguration, isOpen, toggleDrawer, items)}
                drawerPosition={drawerPosition}
                selectedOption={selectedOption}
            />
        </>
    );
};

export default withConfigurations(ConfigureAmount);
