import React from 'react';
import { HpRewardBadge } from '@hpstellar/core';

import withPageComponents from '../../../../page/components/with-page-components';
import AddOnPrice from '../../../../product/components/product-configurations/addon-price';
import ProductPriceBlock from '../../../../product/components/product-price/product-price-block';
import PriceBlock from '../../price-block';
import { useCTOConfigPrice } from '../../../../hooks/useProductPrice';

const PriceContainer = ({ productInitial, hidePrice, className, priceProps, hasPriceSeoText = true }) => {
    const ctoConfig = useCTOConfigPrice(productInitial);
    if (!productInitial) return null;

    const { eol } = productInitial;
    const { price: configPrice, isNotDefaultConfig } = ctoConfig || {};
    return (
        !eol && (
            <div className={className}>
                {!hidePrice && (
                    <AddOnPrice sku={productInitial.sku}>
                        {({ addOnPrice }) => {
                            return (
                                <ProductPriceBlock
                                    className={`pdp-price`}
                                    product={productInitial}
                                    addOnPrice={addOnPrice}
                                    displayPercentageDiscount={true}
                                    priceTag="h3"
                                    hasPriceSeoText={hasPriceSeoText}
                                    disableCustomSaleText={true}
                                >
                                    {props => (
                                        <PriceBlock
                                            {...props}
                                            {...(priceProps || {})}
                                            configPrice={isNotDefaultConfig && configPrice}
                                        />
                                    )}
                                </ProductPriceBlock>
                            );
                        }}
                    </AddOnPrice>
                )}
            </div>
        )
    );
};

export default withPageComponents(PriceContainer, {
    components: ['productInitial'],
});
