import React from 'react';
import loadable from '@loadable/component';

import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
import withPageComponents from '../../../page/components/with-page-components';
import ABTest from '../../../abtest/index';

const PDPCrossSell = loadable(() => import('./cross-sell-container'));

const SECTION_ID = 'recommendedAccessories';

const resolveABTestData = (getABTestAccessories, pdpAccessories, productInitial) => {
    try {
        return typeof getABTestAccessories === 'function'
            ? getABTestAccessories(pdpAccessories, productInitial)
            : pdpAccessories;
    } catch (e) {
        return pdpAccessories;
    }
};

export const CrossSellPlaceholder = ({ id = SECTION_ID }) => {
    return <section id={id} style={{ minHeight: '600px' }}></section>;
};

/**
 * Lazy load container for upsell
 */
export default withPageComponents(
    ({ pdpAccessories, productInitial }) => {
        const { products = {} } = pdpAccessories || {};
        if (!pdpAccessories || Object.keys(products).length < 1) {
            return null;
        }
        return (
            <ABTest experimentID="PDPCrossSell" ssrRender={true}>
                {data => {
                    const { getABTestAccessories } = data;
                    const accessoriesData = resolveABTestData(getABTestAccessories, pdpAccessories) || pdpAccessories;

                    return (
                        <AboveTheFoldOnlyServerRender
                            robots={true}
                            skip={true}
                            offset={600}
                            placeholder={<CrossSellPlaceholder />}
                        >
                            <PDPCrossSell
                                pdpAccessories={accessoriesData || pdpAccessories}
                                productInitial={productInitial}
                                id={SECTION_ID}
                            />
                        </AboveTheFoldOnlyServerRender>
                    );
                }}
            </ABTest>
        );
    },
    { components: ['pdpAccessories', 'productInitial'] },
);
