import React from 'react';

import withPageComponents from '../../../page/components/with-page-components';
import DeliveryMessage from '../../../product/components/delivery-message';
import { hasFreeShipping } from '../../../product/get-product-props/util';
import { getShippingMessage } from '../../../product/get-product-props/cart';
import useSiteConfig from '../../../hooks/useSiteConfig';
import { useGetProductPrice } from '../../../hooks/useProductPrice';

const deliveryMessage = ({ productInitial, pdpSpecialOffers, prefix, device }) => {
    const priceObj = useGetProductPrice(productInitial);
    const { deliveryDate, productBadge } = productInitial || {};
    const { priceSettings } = useSiteConfig();
    const { freeShippingMessage } = priceSettings || {};
    const { subTitle, title, isFree } =
        getShippingMessage(productInitial, priceObj, pdpSpecialOffers)?.shippingMessage || {};

    if ((!subTitle && !title) || device !== 'desktop') return null;
    const showIcon = productBadge !== 'DIGITAL';
    return (
        <>
            {prefix}
            <div className="delivery-message-container">
                <DeliveryMessage
                    message={subTitle.label}
                    icon={subTitle.icon}
                    enableIcon={showIcon}
                    isFree={isFree}
                    freeShippingMessage={freeShippingMessage}
                />
            </div>
        </>
    );
};

export default withPageComponents(deliveryMessage, {
    components: ['productInitial', 'pdpSpecialOffers'],
    device: true,
});
