import React, { useState, useEffect } from 'react';
import { AddToCart } from '@hpstellar/core';
import useDeviceInfo from '../../../hooks/useDeviceInfo';

import AddOnPrice from '../../../product/components/product-configurations/addon-price';
import withConfigurations from '../../../product/components/product-configurations';
import withPageComponents from '../../../page/components/with-page-components';
import useProductPrice from '../../../hooks/useProductPrice';
import useAddToCart from '../../../hooks/useAddToCart';
import { Helpers } from '../../../core/src/helpers';
import { getAddon, addGtmPropsToProductTile } from '../../../metrics/metrics-helpers';
import useCartState from '../../../hooks/useCartState';
import { clickPdpAddToCartWithoutGtmAttributes } from '../../analytics';
import { createObserver } from './util';

import './mobile-add-to-cart.less';

const StickyMobileCart = ({
    price,
    onAdd,
    addText,
    customizeText,
    customizeUrl,
    outOfStock,
    offset = 0,
    isFixed,
    gtmActions,
}) => {
    const stickyStyle = isFixed
        ? {
              position: 'fixed',
              bottom: offset,
              left: 0,
              width: '100%',
              zIndex: 1035,
          }
        : {};

    const [isSticky, setIsSticky] = useState(true);

    useEffect(() => {
        let [cta] = document.getElementsByClassName('pdp-cta-row');
        //if observer is not supported, just show the sticky add to cart
        const observer = createObserver(cta, visible => setIsSticky(!visible));
        return () => {
            observer.unobserve();
        };
    }, []);

    if (!isSticky) {
        return null;
    }
    const add2CartComponent = (
        <AddToCart
            className="pdp-mobile-a2c"
            price={price}
            onAdd={onAdd}
            customizeUrl={customizeUrl}
            addBtnDisabled={outOfStock}
            translations={{
                addText: addText,
                customizeText: customizeText,
            }}
            gtmActions={gtmActions}
        />
    );
    return isFixed ? <div style={stickyStyle}>{add2CartComponent}</div> : add2CartComponent;
};

const getCartProps = (totalPrice, product, ctoConfig) => {
    const {
        regularPrice,
        salePrice,
        ctaText: addText,
        customizeText,
        ctoLink,
        isOOS,
        productType,
        locale,
        currency,
        isComingSoonSku: isComingSoon,
    } = totalPrice;
    let disabledCart = isComingSoon ? false : isOOS;
    if (Helpers.isGiftCard(product)) {
        disabledCart = !!ctoConfig.error;
    }

    return {
        price: {
            currencySymbol: '$',
            regularPrice,
            salePrice,
            productType,
            locale,
            currency,
        },
        customizeText,
        ctoLink,
        isOOS: disabledCart,
        addText,
    };
};

/***
 * Component determines when to render sticky add to cart on mobile devices
 */
const mobileAddToCart = ({ productInitial, ctoLink, offset = 0, ctoConfig, isFixed, addOns }) => {
    const { prices } = useProductPrice([productInitial], { withCTOConfigurations: true });
    const { addToCart, addCTOToCart } = useAddToCart(false, { withCTOConfigurations: true });
    const isMobile = useDeviceInfo(['width']).customBreakpoints.default === 'mobile';
    const { cartId } = useCartState();

    if (productInitial && isMobile) {
        const { sku } = productInitial;
        const addonProduct = addOns && getAddon(sku, addOns);
        const { isNotDefaultColor, isNotDefaultConfig } = ctoConfig || {};
        const isCTO = productInitial.product_type === 'CTO';
        const price = prices[sku];
        const hideCTA =
            productInitial.eol || price.hideAddToCart || (isCTO && (isNotDefaultColor || isNotDefaultConfig));
        if (!price || !price.regularPrice) {
            return null;
        }

        return (
            <AddOnPrice sku={sku} price={price}>
                {({ totalPrice }) => {
                    const { price, customizeText, isOOS, addText } = getCartProps(
                        totalPrice,
                        productInitial,
                        ctoConfig
                    );
                    let list = 'pdp';
                    const gtmActions = addGtmPropsToProductTile(
                        {
                            ...(productInitial || {}),
                            customGtmId: `${list}-${sku}`,
                            sku,
                            price: price || totalPrice,
                        },
                        {
                            list: 'pdp',
                            cartId,
                            enableCtoAddToCart: true,
                            selectedAddonSku: addonProduct && addonProduct.sku,
                            prices,
                        },
                        { [sku]: totalPrice }
                    );

                    return (
                        <StickyMobileCart
                            price={price}
                            onAdd={
                                hideCTA
                                    ? null
                                    : () => {
                                          if (isCTO) {
                                              addCTOToCart(productInitial, 1);
                                          } else {
                                              //TODO: temp fix to trigger xsell modal
                                              let mainBtn = document.getElementById('pdpAddToCartBtn');
                                              if (mainBtn) {
                                                  // prevents additional addToCart event when clicking on the other button.
                                                  clickPdpAddToCartWithoutGtmAttributes(mainBtn);
                                              } else {
                                                  addToCart(productInitial, 1);
                                              }
                                          }
                                      }
                            }
                            customizeText={customizeText}
                            customizeUrl={!isOOS && ctoLink}
                            outOfStock={isOOS}
                            addText={addText}
                            offset={offset}
                            isFixed={isFixed}
                            gtmActions={gtmActions}
                        />
                    );
                }}
            </AddOnPrice>
        );
    }
    return null;
};

export default withPageComponents(withConfigurations(mobileAddToCart), {
    components: ['productInitial'],
    addOns: true,
});
