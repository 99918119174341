import React from 'react';
import loadable from '@loadable/component';
import useDisclaimerTabs from '../../../hooks/useDisclaimerTabs';

import withPageComponents from '../../../page/components/with-page-components';
import withConfigurations from '../../../product/components/product-configurations';

import { Helpers } from '../../../core/src/helpers';

const TechSpecs = loadable(() => import('@hpstellar/core/TechSpecs'));
const BundlesTechSpecs = loadable(() => import('@hpstellar/core/BundlesTechSpecs'));

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];

//checks if a selected config has tech specs and use those instead
const getSpecs = (pdpTechSpecs, ctoConfig) => {
    const { fullSpecs } = ctoConfig || {};
    const { technical_specifications: ctoTechSpecs = EMPTY_ARRAY } = fullSpecs || EMPTY_OBJECT;
    const { technical_specifications = EMPTY_ARRAY } = pdpTechSpecs || EMPTY_OBJECT;
    //only show specs from selected CTO config if they have been
    if (!ctoTechSpecs || ctoTechSpecs.length < 1) {
        return technical_specifications;
    }
    return ctoTechSpecs;
};

const pdpSpecs = ({ pdpTechSpecs, productInitial, ctoConfigurations }) => {
    const { sku } = productInitial || EMPTY_OBJECT;
    const {
        highlights = EMPTY_ARRAY,
        bundleSpecs = EMPTY_ARRAY,
        translations,
        gtmActions,
        datasheets = EMPTY_ARRAY,
    } = pdpTechSpecs || EMPTY_OBJECT;
    const ctoConfig = ctoConfigurations[sku] || EMPTY_OBJECT;
    const selectedTechSpecs = getSpecs(pdpTechSpecs, ctoConfig);

    const [, setDisclaimerTab] = useDisclaimerTabs();

    const interceptClick = ev => {
        if (ev.target.classList.contains('footerLink')) {
            setDisclaimerTab('specs');
        }
    };

    let component = null;
    let hasTechSpecs = highlights.length > 0 || selectedTechSpecs.length > 0 || datasheets.length > 0;

    if (bundleSpecs.length > 0) {
        component = (
            <BundlesTechSpecs
                productsData={bundleSpecs}
                translations={translations}
                accordionProps={{ size: 'small' }}
            />
        );
    }
    if (hasTechSpecs) {
        component = (
            <TechSpecs
                className={highlights.length === 0 ? 'slim' : ''}
                data={{
                    ...pdpTechSpecs,
                    highlights: pdpTechSpecs.highlights.map(highlight => ({
                        ...highlight,
                        value: highlight.value && (
                            <span dangerouslySetInnerHTML={Helpers.createMarkup(highlight.value)} />
                        ),
                    })),
                    technical_specifications: selectedTechSpecs.map(spec => ({
                        ...spec,
                        tooltip:
                            spec.tooltip && spec.tooltip.description
                                ? {
                                      ...spec.tooltip,
                                      description: (
                                          <span
                                              dangerouslySetInnerHTML={Helpers.createMarkup(spec.tooltip.description)}
                                          />
                                      ),
                                  }
                                : spec.tooltip,
                        value: spec.value.map(v => ({
                            value: <span dangerouslySetInnerHTML={Helpers.createMarkup(v.value)} />,
                            subheading: <span dangerouslySetInnerHTML={Helpers.createMarkup(v.subheading)} />,
                        })),
                    })),
                }}
                translations={translations}
                accordionProps={{ size: 'small' }}
                gtmActions={gtmActions}
            />
        );
    }

    return (
        <div id={!hasTechSpecs ? 'techSpecs' : null} className="pdpTechSpecs" onClick={interceptClick}>
            {component}
        </div>
    );
};

export default withConfigurations(withPageComponents(pdpSpecs, { components: ['pdpTechSpecs', 'productInitial'] }));
