import React from 'react';
import loadable from '@loadable/component';
import { Link } from '@hpstellar/core';
import { useStickyHeaderOffset, useSmoothScroll } from '@hpstellar/core/hooks';

const CompatibleProducts = loadable(() => import('@hpstellar/core/CompatibleProducts'));

import withPageComponents from '../../../page/components/with-page-components';

const handleClick = (scrollToTarget, hash) => () => {
    scrollToTarget(document.getElementById(hash));
};

const compatibleProductsLink = ({
    pdpCompatiblePrinters,
    name = 'See Compatible Printers',
    hash = 'compatibleProducts',
}) => {
    const offset = useStickyHeaderOffset();
    const { scrollToTarget } = useSmoothScroll({ offset });
    if (pdpCompatiblePrinters && pdpCompatiblePrinters.length > 0) {
        return (
            <Link className={'compatible-products-link'} onClick={handleClick(scrollToTarget, hash)}>
                {name}
            </Link>
        );
    }
    return null;
};

export const CompatibleProductsLink = withPageComponents(compatibleProductsLink, {
    components: ['pdpCompatiblePrinters'],
});

const compatibleProducts = ({ pdpCompatiblePrinters }) => {
    if (pdpCompatiblePrinters && pdpCompatiblePrinters.length > 0) {
        pdpCompatiblePrinters = pdpCompatiblePrinters.map(item =>
            item.name === '' ? { ...item, name: 'MISSING_VALUE' } : item,
        );
        return (
            <section id="compatibleProducts">
                <CompatibleProducts
                    items={pdpCompatiblePrinters}
                    translations={{
                        linkExpanded: 'Show less',
                    }}
                />
            </section>
        );
    }
    return null;
};

export default withPageComponents(compatibleProducts, { components: ['pdpCompatiblePrinters'] });
