import React from 'react';

import PDP from './pdp';
import SeoMetaData from './pdp-meta-data';
import PDPSchemaMarkUp from './pdp-schema-markup';

import withPage from '../../page';

class PDPContainer extends React.PureComponent {
    render() {
        let { seo, match } = this.props;
        const { url } = match;
        return (
            <>
                <SeoMetaData seo={seo} url={url} />
                <PDP />
            </>
        );
    }
}

export default withPage(PDPContainer, {
    customMetaData: true,
    wrapperClassName: 'pdp-v2',
    showWordCloud: true,
    useGraphQL: true,
    useV2: true,
    noProps: true,
    SchemaMarkupComponent: PDPSchemaMarkUp,
    shouldClearSlugInfo: (store, slug) => {
        try {
            //don't clear slug info when switching from a shadow CTO sku
            const { slugInfo } = store;
            const { components } = slugInfo;
            const { pdpShadowCTO = {}, productInitial = {} } = components;
            const { shadowCTOEnabled, selectedFilterGroup } = pdpShadowCTO;
            const { shadowCTOSkus = {} } = productInitial;
            if (shadowCTOEnabled) {
                let isCTORouteChange = false;
                //if filters are enabled merge all products into single map
                const skuMap = !selectedFilterGroup
                    ? shadowCTOSkus
                    : Object.keys(shadowCTOSkus).reduce((r, group) => {
                          return { ...r, ...shadowCTOSkus[group] };
                      }, {});
                Object.keys(skuMap).every(key => {
                    const shadowCTO = skuMap[key];
                    const { url = '' } = shadowCTO;
                    isCTORouteChange = decodeURIComponent(url).indexOf(decodeURIComponent(slug)) >= 0;
                    return !isCTORouteChange;
                });
                return !isCTORouteChange;
            }
        } catch (e) {
            console.error(`Error checking shadow cto route change ${e}`);
        }

        return true;
    },
});
