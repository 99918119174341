import React from 'react';
import loadable from '@loadable/component';

import withPageComponents from '../../../page/components/with-page-components';

const CTOOptionsContainer = loadable(() => import('./cto-options-container'));

/**
 * "Shadow" CTO component - simulates configurable options be linking options to existing product pages
 */
export default withPageComponents(
    ({ pdpShadowCTO, translations, vanityUrl, isClientGQLLoading }) => {
        const { shadowCTOEnabled } = pdpShadowCTO || {};
        if (!shadowCTOEnabled) {
            return null;
        }
        return <CTOOptionsContainer translations={translations} />;
    },
    { components: ['pdpShadowCTO'], gqlClientLoadStatus: true },
);
