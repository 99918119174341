import React from 'react';
import { StockIndicator, Typography } from '@hpstellar/core';

import InventoryMessage from './inventory-message';

const ProductStatusBadge = ({
    product,
    displayShipMessage,
    showPreOrderMsg,
    stockIndicatorProps,
    shipDate,
    ...otherProps
}) => {
    return (
        <InventoryMessage product={product} showOOS={true} showPreOrderMsg={showPreOrderMsg}>
            {({ stock, shippingMsg, price }) => {
                const { shipMessage: defaultShippingMessage, estShipMsg } = product;
                const { stockMessage, stockStatus, preOrder, backOrderFlag, invAvaDate, isOOS, isComingSoonSku } =
                price;
                let deliveryMessage = displayShipMessage && !isOOS
                ? shipDate || estShipMsg || shippingMsg || defaultShippingMessage
                : null;
                //do not show stock status for Coming Soon SKU
                if ((!stockMessage && !backOrderFlag) || (isOOS && isComingSoonSku)) return <div className="spacer" />;

                return (
                    <>
                        {preOrder || backOrderFlag ? (
                            <Typography variant="bodySmall" className={'shippingDateText'}>
                                {deliveryMessage}
                            </Typography>
                        ) : (
                            <StockIndicator
                                className="stockIndicator"
                                color={stockStatus}
                                stockMessage={stockMessage}
                                deliveryMessage={deliveryMessage}
                                {...stockIndicatorProps}
                                size={stockIndicatorProps?.size || (deliveryMessage ? 'large' : 'small')}
                                {...otherProps}
                            />
                        )}
                    </>
                );
            }}
        </InventoryMessage>
    );
};

export default ProductStatusBadge;
