import React from 'react';

import useDeviceInfo from '../../hooks/useDeviceInfo';
import CompareDrawer from '../../compare/components/compare-drawer';
import MobileAddToCart from './sticky-nav/mobile-add-to-cart';

export default function PDPFooter({}) {
    const isMobile = useDeviceInfo(['width']).customBreakpoints.default === 'mobile';

    return <CompareDrawer footer={isMobile ? <MobileAddToCart /> : null} />;
}
