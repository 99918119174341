import React, { useState } from 'react';
import { CarePack, Link } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import withConfigurations from '../../../product/components/product-configurations';
import useProductPrice from '../../../hooks/useProductPrice';
import CompareCarePacks from './compare-carepacks';
import useTestFlags from '../../../abtest/useTestFlags';

import './carepack.less';

const getCarePackDuration = name => {
    try {
        return (name.match(/\d[\-\s]?[\yr\year]/gi) || [''])[0].replace(/\D/g, '');
    } catch (e) {}
};

const hasAccidentalDamageCoverage = name => {
    try {
        return name?.toLowerCase().indexOf('accidental damage') > -1;
    } catch (e) {}
};

const getConfigurations = (carepacks, defaultCarePack, prices, selectedCarepack) => {
    let recommended = 'HP recommends';
    const carePackItems = carepacks.reduce(
        (r, c, i) => {
            const { sku, violatorMsg, viewDetails, viewDetailsText = 'View Details', name, ...rest } = c;
            const cpPrice = prices[sku];
            const { salePriceSuffix, ...price } = cpPrice;
            const isRecommended = !!violatorMsg;
            if (isRecommended) {
                recommended = violatorMsg;
            }
            const label = viewDetails ? (
                <div>
                    {c.name}
                    <br />
                    <Link size="small" to={viewDetails} variation="body" weight="light">
                        {viewDetailsText}
                    </Link>
                </div>
            ) : c.label ? (
                c.label
            ) : (
                c.name
            );
            r.push({
                label,
                isRecommended,
                price: { ...price, translations: { salePriceSuffix } },
                sku: c.sku,
                gtmValue: c.gtmValue,
                accidentalDamageCoverage: hasAccidentalDamageCoverage(name),
                duration: getCarePackDuration(name),
                ...rest,
            });
            return r;
        },
        defaultCarePack
            ? [
                  {
                      label: defaultCarePack.label || defaultCarePack.name,
                      duration: getCarePackDuration(defaultCarePack.name),
                      accidentalDamageCoverage: hasAccidentalDamageCoverage(defaultCarePack.name),
                      isIncluded: true,
                      sku: 'default',
                  },
              ]
            : []
    );
    return { recommended, carePackItems };
};

const onChange = (sku, setAddons, carepacks, value, linkId) => {
    const selectCarePack = carepacks.find(c => c.sku === value);
    setAddons(sku, selectCarePack ? [{ product: selectCarePack, qty: 1 }] : []);
};

const EMPTY_OBJ = {};
const carepacks = ({
    productInitial,
    pdpSettings,
    pdpCarePackBundle,
    addOns,
    setAddOns,
    trackCustomMetric,
    device,
    showFor = ['desktop'],
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { carepackmodule, ctoCarePack } = useTestFlags(['carepackmodule', 'ctoCarePack']);
    const { ctoCarePackEnabled } = ctoCarePack || {};
    const { carePackSettings } = pdpSettings || {};
    const { enabled: serviceBundleEnabled } = carepackmodule || carePackSettings || {};
    const {
        carepacks: standardCarePacks,
        label,
        defaultCarePack: standardDefault,
        gtmActions,
        serviceBundle,
    } = pdpCarePackBundle || EMPTY_OBJ;
    const { product_type, sku, isSMB, eol } = productInitial || EMPTY_OBJ;
    const {
        carepacks: serviceBundleCarePacks,
        defaultCarePack: serviceBUndleDefault,
        translations: serviceBundleTranslations,
    } = serviceBundle || EMPTY_OBJ;
    const isServiceBundle = serviceBundleEnabled && serviceBundleCarePacks && serviceBundleCarePacks.length > 0;
    const translations = isServiceBundle ? serviceBundleTranslations : {};
    const defaultCarePack = isServiceBundle ? serviceBUndleDefault : standardDefault;
    const carepacks = isServiceBundle ? serviceBundleCarePacks : standardCarePacks;
    const hasCarePack = carepacks && carepacks.length > 0;
    const { prices } = useProductPrice(carepacks);

    if (!hasCarePack || (product_type === 'CTO' && !ctoCarePackEnabled) || !showFor.includes(device) || eol) {
        return null;
    }
    const addOnCarepacks = addOns[sku];
    const selectedCarepack = (addOnCarepacks && addOnCarepacks[0] && addOnCarepacks[0].product) || defaultCarePack;

    const { recommended, carePackItems } = getConfigurations(carepacks, defaultCarePack, prices, selectedCarepack);
    const isSmbNoCarepack = isSMB && !hasCarePack;

    const onChangeSelection = e => {
        onChange(sku, setAddOns, carepacks, e?.target?.value);
        setIsModalOpen(false);
        // TODO: remove once stellar has a fix for their modal close
        document.body.style = null;
    };

    const modalClose = () => setIsModalOpen(false);

    return (
        <div className="pdp-carepack-module">
            <CarePack
                selectedValue={selectedCarepack.sku}
                items={carePackItems}
                onChange={onChangeSelection}
                translations={{
                    title: label,
                    learnMore: 'LEARN MORE',
                    included: 'Included',
                    recommended,
                    ...translations,
                }}
                onLearnMore={() => {
                    setIsModalOpen(true);
                }}
                hideLearnMore={isSmbNoCarepack}
                gtmActions={gtmActions}
                abTest={isServiceBundle}
            />
            <CompareCarePacks
                isOpen={isModalOpen}
                carePackItems={carePackItems}
                onChangeSelection={onChangeSelection}
                selectedCarepack={selectedCarepack}
                modalClose={modalClose}
                serviceBundle={isServiceBundle ? serviceBundle : null}
            />
        </div>
    );
};

export default withConfigurations(
    withPageComponents(carepacks, { components: ['pdpCarePackBundle', 'productInitial', 'pdpSettings'], device: true })
);
