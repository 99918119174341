import { addGtmPropsToProductTile } from '../../../metrics/metrics-helpers';
import getProductProps from '../../../product/get-product-props';

/**
 * Derives commonly use props from product and price information
 * @param {*} product
 * @param {*} price
 * @param {*} hooks - Pass in hook, like useAddToCart and useProductCompare
 */
function getStickyNavProductProps(product, priceObj, hooks, options = { resizeImage: false }, analyticsData, trackCustomMetric) {

    const {
        sku,
    } = product;

    let gtmActions;
    if (analyticsData) {
        let { list, gtmId, position, prices, hsResID } = analyticsData;
        gtmActions = addGtmPropsToProductTile(
            {
                ...product,
                customGtmId: `${list}-${sku}`,
                sku,
                price: prices || priceObj,
                position,
                hsResID,
            },
            analyticsData,
            { [sku]: priceObj }
        );
    }

    const productTileProps = {
        ...getProductProps(
            product, priceObj, hooks, options, analyticsData, trackCustomMetric
        ),
        gtmActions
    };

    return productTileProps;
}

export default getStickyNavProductProps;
