import React from 'react';

import { Cross, Check } from '@hpstellar/icons';
import { Button, Link, PriceBlock } from '@hpstellar/core';
import Accordion from './accordion';

import './learn-more.less';

const getYearLabel = duration => (duration === '1' ? 'year' : 'years');

const CoveragePlan = ({ item, className, row }) => {
    const { alt } = row;
    const { duration } = item;

    if (alt) {
        return (
            <div className={`coverage${className}`}>
                {duration} {getYearLabel(duration)}
            </div>
        );
    }

    return (
        <div className={`coveragePlan${className}`}>
            {
                <span className="yearHeading" style={!duration ? { visibility: 'hidden' } : null}>
                    <span className="yearDuration">{duration}</span>
                    {getYearLabel(duration)}
                </span>
            }
            <span className="description">{item.label}</span>
        </div>
    );
};

const Price = ({ item, className }) => {
    if (item.isIncluded) return <div className="price">Included with device</div>;
    const { price = {} } = item || {};
    return (
        <div className={`price${className}`}>
            <PriceBlock priceDifferenceVariant="amount" style="slim" size="small" {...price} />
        </div>
    );
};

const CheckMark = ({ item, className, row }) => {
    const { key } = row;
    const checkMark = (
        <div className={`checkMark${className}`}>
            <Check size="s" />
        </div>
    );

    switch (true) {
        case key === 'accidental' && item.accidentalDamageCoverage:
            return checkMark;
        case key === 'support':
            return checkMark;
        case key === 'twentyFourSeven' && !item.isIncluded:
            return checkMark;
        /* add this case back if monitor coverage is requested
        case key === 'monitor' && item.monitorCoverage:
            return checkMark; */
        default:
            return null;
    }
};

const getLink = () => {
    //TODO: Temp make URLS absolute till we figure out how to handle these non suported relative routes
    const domain = typeof document !== 'undefined' ? `${document.location.origin}` : '';
    const link = `${domain}/${process.env.BASENAME}/ContentView?eSpotName=Risk-Free-Care-Pack&storeId=10151&catalogId=10051&langId=-1&jumpid=020116_PDPModal`;
    return link;
};

const LearnMoreLink = ({ className, to }) => {
    return (
        <div className={`learnMoreLink${className}`}>
            <Link to={to}>Learn More</Link>
        </div>
    );
};

const Selector = ({ item, className, onChange, selected }) => {
    if (item.sku === selected)
        return <div className={`included${item.isRecommended ? ' recommended' : ''}`}>Included</div>;
    const { gtmValue } = item || {};
    const event = { target: { value: item.sku } };
    return (
        <div className={`selector${className}`}>
            <Button
                variation="secondary"
                size="small"
                isFullWidth
                onClick={() => onChange(event)}
                data-gtm-value={gtmValue}
                data-gtm-id="product"
                data-gtm-category="linkClick"
            >
                Select
            </Button>
        </div>
    );
};

const RowLabel = ({ label, description }) => {
    if (!description)
        return (
            <div className="rowLabel">
                <span className="label">{label}</span>
            </div>
        );
    return <Accordion title={label} description={description} />;
};

const ROWS = [
    { label: 'Coverage Plan', key: '', reducer: props => <CoveragePlan {...props} /> },
    { label: 'Price', reducer: props => <Price {...props} /> },
    {
        label: 'Total year of Hardware Coverage',
        description: 'From date of purchase.',
        reducer: props => <CoveragePlan {...props} />,
        alt: true,
    },
    {
        label: 'Initial Support & Software support',
        description: 'We provide initial setup support and a team to assist you with HP purchased software.',
        reducer: CheckMark,
        key: 'support',
    },
    {
        label: '24/7 Support',
        description: 'Get support from professionals who know HP products best - anytime you need.',
        reducer: CheckMark,
        key: 'twentyFourSeven',
    },
    {
        label: 'Accidental Damage Protection',
        description:
            'Protection starts as soon as your product ships and covers accidental drops, spills, cracked screens, and more. Available only on select HP Care Packs.',
        reducer: CheckMark,
        key: 'accidental',
    },
    {
        label: 'Monitor coverage',
        description: 'Includes protection for your monitor too!',
        reducer: CheckMark,
        key: 'monitor',
    },
    {
        label: 'Anti-theft protection (LoJack)',
        description:
            'If your device gets lost or stolen, we can lock your device, track it, and work with authorities to help recover it. We can also wipe your hard drive so your private information stays private. Available only on select HP Care Packs.',
        reducer: CheckMark,
        key: 'lojack',
    },
    {
        label: 'Risk free rebate eligible',
        key: 'riskFree',
        reducer: props => {
            const { item } = props;
            if (!item.riskFreeEligible) {
                return null;
            }
            return <LearnMoreLink {...props} to={getLink()} />;
        },
    },
    {
        label: 'HP Services Promise rebate eligible',
        key: 'hpServicesPromiseEligible',
        reducer: props => {
            const { item } = props;
            if (!item.hpServicesPromiseEligible) {
                return null;
            }
            return <LearnMoreLink {...props} to="/us-en/shop/cv/business-care-packs-rebate" />;
        },
    },
    { label: '', reducer: props => <Selector {...props} /> },
];

const LearnMore = ({ items, onChange, selected, modalClose }) => {
    if (items.length < 3) {
        const blanks = 3 - items.length;
        for (let index = 0; index < blanks; index++) {
            items.push(false);
        }
    }

    const contentRows = ROWS.reduce((contentRows, row) => {
        const contentRow = [];
        contentRow.push(<RowLabel {...row} />);
        let skip = true;
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            const className = item.isRecommended ? ' recommended' : '';
            let cell = item && row.reducer({ item, className, row, onChange, selected });
            if (cell) {
                skip = false;
            } else {
                cell = <div className={className} />;
            }
            contentRow.push(cell);
        }
        if (skip) return contentRows;

        contentRows.push(<div className="contentRow">{contentRow}</div>);
        return contentRows;
    }, []);

    return (
        <div className="learnMore">
            <div className="header">
                <span className="left" />
                <span className="center">
                    <h3 className="title">HP's got your back</h3>
                    <p className="subtitle">
                        Be prepared for anything. Enhance and extend your protection beyond the standard limited
                        warranty with an HP Care Pack.
                    </p>
                </span>
                <span className="right">
                    <button className="modalCloseButton" onClick={modalClose}>
                        <Cross color="white" size="m" />
                    </button>
                </span>
            </div>
            <div className="gridContainer">
                <div className="content">{contentRows}</div>
            </div>
        </div>
    );
};

export default React.memo(LearnMore);
