import React from 'react';
import { CollapseWrapper, OfferBlock, Container, Grid } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';

import { Helpers } from '../../../core/src/helpers';

import './pdp-offers.less';

const pdpOffers = ({ pdpSpecialOffers, id = 'specialOffers', title = 'Offers', titleTag = 'h5' }) => {
    if (pdpSpecialOffers && pdpSpecialOffers.length > 0) {
        return (
            <CollapseWrapper
                title={title}
                desktopTitle={title}
                desktopTitleTag={titleTag}
                id={id}
                accordionTitleProps={{
                    tag: titleTag,
                    id: 'specialOffersHeader',
                    'aria-controls': 'specialOffersContent',
                }}
                accordionProps={{
                    isStatic: true,
                    defaultExpanded: true,
                }}
            >
                <Container className="pdpOffers">
                    <Grid container verticalGutter>
                        {pdpSpecialOffers.map((offer, i) => (
                            <Grid col lg={4} key={i}>
                                <OfferBlock
                                    className="pdp-offer-list"
                                    title={
                                        offer?.title && (
                                            <span dangerouslySetInnerHTML={Helpers.createMarkup(offer.title)} />
                                        )
                                    }
                                    text={
                                        offer?.text && (
                                            <span dangerouslySetInnerHTML={Helpers.createMarkup(offer.text)} />
                                        )
                                    }
                                    body={offer?.body?.map(content =>
                                        content?.text ? (
                                            {
                                                ...content,
                                                text: (
                                                    <span
                                                        dangerouslySetInnerHTML={Helpers.createMarkup(content.text)}
                                                    />
                                                ),
                                            }
                                        ) : (
                                            <span dangerouslySetInnerHTML={Helpers.createMarkup(content)} />
                                        )
                                    )}
                                    inGrid
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </CollapseWrapper>
        );
    }
    return null;
};

export default withPageComponents(pdpOffers, { components: ['pdpSpecialOffers'] });
