import React from 'react';

import withPageComponents from '../../../../page/components/with-page-components';
import useProductPrice from '../../../../hooks/useProductPrice';
import CarePackModule from '../../carepacks';
import { CompatibleProductsLink } from '../../compatible-products';
import CTOOptions from '../../cto-options';
import PreOrderDisclaimer from '../pre-order-disclaimer-link';
import FacebookChatbot from '../facebook-chatbot';
import CarePackMessage from '../ecare-pack-message';

const addOnSection = ({ productInitial, pdpCarePackBundle }) => {
    const { carepacks } = pdpCarePackBundle || {};
    const { prices } = useProductPrice([productInitial, ...(Array.isArray(carepacks) ? carepacks : [])]);
    const { plcode, sku } = productInitial || {};
    const priceObj = (prices || {})[sku];

    if (!productInitial) return null;

    return (
        <div>
            <CompatibleProductsLink />
            <CTOOptions />
            <PreOrderDisclaimer priceObj={priceObj} />
            <CarePackModule showFor={['desktop', 'tablet', 'mobile']} />
            <CarePackMessage />
            <FacebookChatbot plcode={plcode} />
        </div>
    );
};

addOnSection.whyDidYouRender = true;

export default withPageComponents(addOnSection, {
    components: ['productInitial', 'pdpImages', 'pdpCarePackBundle'],
});
