import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useThirdPartyTags } from '../hooks/useSiteConfig';
import useMutationObserver from '../hooks/useMutationObserver';
import AboveTheFoldOnlyServerRender from '../shared/components/above-the-fold-only-server-render';

const UGCContainer = ({ containerProps, placeHolder, onContentChange, contentElement }) => {
    const { 'data-crl8-container-id': containerID } = containerProps;
    useMutationObserver(contentElement, onContentChange);
    useEffect(() => {
        try {
            if (window.crl8) {
                window.crl8.createExperience(containerID);
            }
        } catch (e) {}
        return () => {
            try {
                window.crl8.destroyExperience(containerID);
            } catch (e) {}
        };
    }, []);
    return <div {...containerProps}>{placeHolder}</div>;
};

export default ({
    containerID,
    filter,
    skipSSR = true,
    lazyLoadExperience = true,
    placeHolder,
    onContentChange,
    renderOn,
}) => {
    const { visualSocialSiteName } = useThirdPartyTags(['visualSocialSiteName']);
    const contentElement = useRef(null);
    if (!containerID || !visualSocialSiteName) {
        return null;
    }
    const props = {
        'data-crl8-container-id': containerID,
        ...(filter ? { 'data-crl8-filter': filter } : {}),
        ...(lazyLoadExperience ? { 'data-crl8-lazy-load-experience': true } : {}),
    };
    return (
        <AboveTheFoldOnlyServerRender skip={skipSSR} renderOn={renderOn}>
            <Helmet>
                <script type="text/javascript">
                    {`var CRL8_SITENAME = '${visualSocialSiteName}';!function(){var e=window.crl8=window.crl8||{},t=!1,i=[];e.ready=function(e){t?e():i.push(e)},e.pixel=e.pixel||function(){e.pixel.q.push(arguments)},e.pixel.q=e.pixel.q||[];var n="visualSocial",c=window.document,l=c.createElement("script"),o=e.debug||-1!==c.location.search.indexOf("crl8-debug=true")?"js":"min.js";l.async=!0,l.src="https://edge.curalate.com/sites/"+CRL8_SITENAME+"/site/latest/site."+o,l.onload=function(){t=!0,i.forEach(function(e){e()})},l.id=n;var a=c.getElementsByTagName("script")[0];document.getElementById(n)||a.parentNode.insertBefore(l,a.nextSibling)}();`}
                </script>
            </Helmet>
            <div className="ugc-container" ref={contentElement}>
                <UGCContainer
                    contentElement={contentElement}
                    containerProps={props}
                    placeHolder={placeHolder}
                    onContentChange={onContentChange}
                />
            </div>
        </AboveTheFoldOnlyServerRender>
    );
};
