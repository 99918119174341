import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import useUserData from '../../../hooks/useUserData';
import { useThirdPartyTags } from '../../../hooks/useSiteConfig';
import useProductPrice from '../../../hooks/useProductPrice';
import { Helpers } from '../../../core/src/helpers';
import EtrWCSApi from '../../../core/src/etr-wcs-api-client';

import './consumer-finance-widget.less';

const sendPreqaulifyData = function (confirmtoken, responsedata, errorstatus) {
    var params = { ...(responsedata || {}) };
    EtrWCSApi.financing.prequalified(params);
};

const trackOnComplete = (token, data, error, context) => {
    try {
        const checkoutPrequalifyContext = context.source;
        const lenderStatuslength = data.lendersStatus ? data.lendersStatus.length : 0;
        if (checkoutPrequalifyContext == 'APPLY') {
            if (!error) {
                if (data.lendersStatus) {
                    for (var i = 0; i < lenderStatuslength; i++) {
                        if (
                            data.lendersStatus[i].status.toLowerCase().includes('approved') ||
                            data.lendersStatus[i].status.toLowerCase().includes('new')
                        ) {
                            dataLayer.push({
                                event: 'e_linkClick',
                                linkPlacement: 'hp-financing',
                                linkID: 'prequal-success-' + data.lendersStatus[i].name,
                            });
                            break;
                        }
                    }
                }
            } else {
                if (data.lendersStatus) {
                    if (lenderStatuslength > 0) {
                        for (var i = 0; i < lenderStatuslength; i++) {
                            var failedLender = data.lendersStatus[i].name;
                            var failedLenderStatus = data.lendersStatus[i].status.toLowerCase();
                            dataLayer.push({
                                event: 'e_linkClick',
                                linkPlacement: 'hp-financing',
                                linkID: 'prequal-fail-' + failedLender + '-' + failedLenderStatus,
                            });
                            break;
                        }
                    } else {
                        dataLayer.push({
                            event: 'e_linkClick',
                            linkPlacement: 'hp-financing',
                            linkID: 'prequal-fail-close',
                        });
                    }
                } else {
                    dataLayer.push({
                        event: 'e_linkClick',
                        linkPlacement: 'hp-financing',
                        linkID: 'prequal-fail-close',
                    });
                }
            }
        }
    } catch (e) {}
};

const onComplete = function (token, data, error, context) {
    var cartPrequalifyContext = context.source;
    trackOnComplete(token, data, error, context);
    if (!error && cartPrequalifyContext == 'APPLY') {
        if (data.lendersStatus) {
            var lenderStatuslength = data.lendersStatus.length;
            for (var i = 0; i < lenderStatuslength; i++) {
                if (data.lendersStatus[i].status.toLowerCase().includes('approved')) {
                    Helpers.setCookie('HP_FINANCE_PREQUALIFIED_LENDER', data.lendersStatus[i].name, 1);
                    break;
                }
            }
        }
        sendPreqaulifyData(token, data, error);
    }
};

export default ({ product, price, variant }) => {
    const [isMounted, setIsMounted] = useState(false);
    const { consumerFinance } = useThirdPartyTags(['consumerFinance']);
    const { userData } = useUserData();
    const { isccf } = userData.profileData || {};
    const { HPFinance_Promo, sku } = product || {};
    const { prices } = useProductPrice(price ? [] : [product]);
    const { salePrice } = price || (prices || {})[sku] || {};
    const {
        financePageUrl = '/us-en/shop/cv/myhpfinance',
        apiKey,
        storeId,
        delegatedMerchantId,
        script,
        minPrice = 200,
    } = consumerFinance || {};
    useEffect(() => {
        setIsMounted(true);
        window.onLoadChargeAfterSDKScript = function () {
            var config = {
                apiKey,
                storeId,
                ...(delegatedMerchantId ? { delegatedMerchantId } : {}),
                onComplete,
            };

            window.ChargeAfter.init(config);
        };
    }, []);
    useEffect(() => {
        try {
            ChargeAfter.promotions.update();
        } catch (e) {}
    }, [salePrice, sku]);

    const clickHandler = useCallback(e => {
        try {
            e.stopPropagation();
            //check target if it's a button and inner text is "Learn More"
            if (
                (e.target.tagName === 'BUTTON' && e.target.innerText.toLowerCase().indexOf('learn') > -1) ||
                e.target.innerText.toLowerCase().indexOf('see') > -1
            ) {
                dataLayer.push({ event: 'e_linkClick', linkPlacement: 'hp-financing', linkID: 'learn-more' });
            } else if (e.target.tagName === 'BUTTON' && e.target.innerText.toLowerCase().indexOf('prequalify') > -1) {
                dataLayer.push({ event: 'e_linkClick', linkPlacement: 'hp-financing', linkID: 'prequal' });
            }
        } catch (e) {}
    });

    if (!HPFinance_Promo || !consumerFinance || salePrice < minPrice || isccf) {
        return null;
    }

    return (
        <>
            {isMounted && (
                <Helmet>
                    <script type="text/javascript">
                        {`
                      var script = document.createElement('script');
                      script.src = '${script}?t=' + Date.now();
                      script.type = 'text/javascript';
                      script.async = true;
                      script.onload = window.onLoadChargeAfterSDKScript;
                      document.body.appendChild(script);
                      `}
                    </script>
                </Helmet>
            )}
            <div
                onClick={clickHandler}
                className={`ca-promotional-widget${variant ? ' ' + variant : ''}`}
                data-widget-type="default-template"
                data-widget-item-sku={sku}
                data-widget-item-price={salePrice}
                data-widget-financing-page-url={financePageUrl}
            ></div>
        </>
    );
};
