import React from 'react';
import { Typography } from '@hpstellar/core/visId';

import './discount-price-block.less';

export default ({ violator, violatorBgColor = "#af1653", saveMessage, priceDifference, customSaleText }) => {
    if (!priceDifference) return null;

    return (
        <div className="discount-save-block">
            {violator && (
            <Typography tag="span" variant="caption" weight={700} className="discount" style={{backgroundColor: violatorBgColor}}>
                    {violator}
                </Typography>
            )}
            {priceDifference && !customSaleText && (
                <Typography tag="span" variant="caption" weight={700} className="price-diff">
                    {saveMessage} {priceDifference}
                </Typography>
            )}
            {customSaleText && (
                <Typography tag="span" variant="caption" weight={700} className="price-diff">
                    {customSaleText}
                </Typography>
            )}
        </div>
    );
};
