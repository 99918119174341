import React, { useState, useCallback } from 'react';
import { StickyHeader } from '@hpstellar/core';

import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';

import AddOnPrice from '../../../product/components/product-configurations/addon-price';
import withPageComponents from '../../../page/components/with-page-components';
import withConfigurations from '../../../product/components/product-configurations';
import useScrollTarget from '../../../hooks/useScrollTarget';
import useProductPrice from '../../../hooks/useProductPrice';
import useAddToCart from '../../../hooks/useAddToCart';
import useCartState from '../../../hooks/useCartState';
import getStickyNavProductProps from './sticky-nav-product-props';
import { Helpers } from '../../../core/src/helpers';
import { formatMetricValue, getAddon } from '../../../metrics/metrics-helpers';
import { getProdDetailsTabWithGtm } from './analytics';
import { clickPdpAddToCartWithoutGtmAttributes } from '../../analytics';
import { NAV_SECTIONS } from '../constants';
import useLoadLazyComponent from '../../../shared/components/above-the-fold-only-server-render/useLoadLazyComponent';
import useLazyScroll from '../../../hooks/useLazyScroll';
import PDPFooter from '../pdp-footer';
import { ugcContainerID } from '../ugc';

import './sticky-nav.less';

const { reviews, reviewsQA } = NAV_SECTIONS;
const MIN_HEIGHT = 58;

const FEATURE_LINK = {
    title: 'Features',
    hash: 'features',
};
const EMPTY_OBJECT = {};

const getAnchorLinks = (
    product,
    offers = [],
    pdpAccessories,
    pdpSimilarProductsLink,
    pdpSimilarProducts,
    exclusiveFeatures,
    pdpTechSpecs,
    productPrice,
    ugcAvailable
) => {
    let { name, sku, social_gallery = true } = product || {};
    let anchorLinks = [getProdDetailsTabWithGtm('Overview', 'pdpOverview', name, 'overview')];
    if (pdpAccessories && pdpAccessories.products && Object.keys(pdpAccessories.products).length > 0) {
        anchorLinks.push(getProdDetailsTabWithGtm(pdpAccessories.navTitle, 'recommendedAccessories', name));
    }
    //add features in 3rd position if available
    if (exclusiveFeatures) {
        anchorLinks.push(getProdDetailsTabWithGtm(FEATURE_LINK.title, FEATURE_LINK.hash, name, 'features'));
    }
    if (
        pdpTechSpecs &&
        ((pdpTechSpecs.technical_specifications && pdpTechSpecs.technical_specifications.length > 0) ||
            (pdpTechSpecs.bundleSpecs && pdpTechSpecs.bundleSpecs.length > 0) ||
            (pdpTechSpecs.datasheets && pdpTechSpecs.datasheets.length > 0))
    ) {
        anchorLinks.push(
            getProdDetailsTabWithGtm(
                'Specs',
                pdpTechSpecs.bundleSpecs && pdpTechSpecs.bundleSpecs.length > 0 ? 'bundlesTechSpecs' : 'techSpecs',
                name,
                'specs'
            )
        );
    }
    if (offers.length > 0) {
        anchorLinks.push(getProdDetailsTabWithGtm('Offers', 'specialOffers', name, 'offers'));
    }
    if (productPrice && productPrice.preOrder) {
        anchorLinks.push(getProdDetailsTabWithGtm("Pre-order FAQ's", 'faq', name, 'faq'));
    }

    if (product && social_gallery && ugcAvailable) {
        anchorLinks.push({
            title: '#MYHP',
            hash: ugcContainerID,
            gtmValue: sku,
            gtmId: 'bv-social',
            gtmCategory: 'prodDetailsTab',
        });
    }

    const reviewTabbedCondition = element => {
        return element && element.dataset.active === 'true';
    };
    anchorLinks.push({
        title: reviews.navTitle || reviews.title,
        hash: reviews.hash,
        gtmValue: sku,
        gtmId: sku,
        gtmCategory: 'readReview',
        activeCondition: reviewTabbedCondition,
    });

    anchorLinks.push({
        title: reviewsQA.navTitle || reviewsQA.title,
        hash: reviewsQA.hash,
        gtmValue: name,
        gtmId: 'questionsAndAnswers',
        gtmCategory: 'prodDetailsTab',
        activeCondition: reviewTabbedCondition,
    });

    if (
        pdpSimilarProductsLink ||
        (pdpSimilarProducts && pdpSimilarProducts.products && pdpSimilarProducts.products.length > 0)
    ) {
        anchorLinks.push(getProdDetailsTabWithGtm('Similar', 'similarProducts', name, 'similar'));
    }

    return anchorLinks;
};

const StickyNAV = ({ anchorLinks, productProps, onAdd, getCTOLink, stickyNavGtmActions, eolCTA, replacementCTA }) => {
    const { product, priceProps: price, gtmActions, purchaseBtnProps } = productProps;
    const { ctaText } = price || {};
    const { name, product_type } = product;
    //check purchase button props. if unavailable default to price ctaText
    const { children: purchaseBtnText = ctaText, disabled: disablePurchase } = purchaseBtnProps || {};
    //remove bundle flag for now as it is not in the comps and also takes up to much space and overflows. Ask Stellar Component team to take a look
    const { bundle, ...restPrice } = price || EMPTY_OBJECT;
    const prodObj = {
        name,
        price: restPrice,
    };
    const [loadLazyComponent] = useLoadLazyComponent();
    const loadReviews = useCallback(() => {
        loadLazyComponent(reviews.key);
    }, []);
    const [activeLink, setActiveLink] = useState('');
    const [scrollToTarget] = useLazyScroll();
    const inRange = useScrollTarget(
        'nav-boundary',
        undefined,
        lastKnownScrollPosition => {
            let activeHash;
            anchorLinks.forEach(({ hash, activeCondition = () => true }) => {
                let anchorElem = document.getElementById(hash);
                if (anchorElem && lastKnownScrollPosition > anchorElem.offsetTop - 150 && activeCondition(anchorElem)) {
                    activeHash = hash;
                }
            });
            setActiveLink(activeHash);
        },
        MIN_HEIGHT
    );

    let addToCartGtmValues = gtmActions && gtmActions.get('addToCart');
    let customizeGtmValues = gtmActions && gtmActions.get('customize');
    let fullGtmActions = stickyNavGtmActions instanceof Map ? stickyNavGtmActions : new Map();
    fullGtmActions.set('addToCart', addToCartGtmValues);
    fullGtmActions.set('customize', customizeGtmValues);
    const customizeUrl = replacementCTA ? replacementCTA.to : price && !disablePurchase && getCTOLink(product, 1);
    const customizeText = replacementCTA ? replacementCTA.children : purchaseBtnText;
    const addText = eolCTA
        ? eolCTA.children
        : product_type !== 'CTO' || disablePurchase
        ? purchaseBtnText
        : 'Add to cart';
    /**
     * Temp wrapper div to reserve space for SSR
     */

    return (
        <>
            <div style={{ minHeight: `${MIN_HEIGHT}px` }} onMouseEnter={loadReviews}>
                <StickyHeader
                    className="pdp-nav"
                    product={prodObj}
                    anchorLinks={anchorLinks}
                    sticky={inRange}
                    upperBarVisible={inRange}
                    activeLink={activeLink}
                    onAdd={(eolCTA || (price && !price.hideAddToCart)) && onAdd}
                    customizeUrl={customizeUrl}
                    addBtnDisabled={disablePurchase}
                    gtmActions={fullGtmActions}
                    translations={{
                        addText,
                        customizeText,
                    }}
                    /* fake CTO for extra CA for EOL products */
                    productType={replacementCTA ? 'cto' : undefined}
                    customizeBtnDisabled={replacementCTA && replacementCTA.disabled}
                />
            </div>
            <AboveTheFoldOnlyServerRender skip={true} renderOn="load">
                <PDPFooter hideShadowFooter={inRange} />
            </AboveTheFoldOnlyServerRender>
        </>
    );
};

const navContainer = ({
    productInitial,
    pdpSpecialOffers,
    pdpAccessories,
    pdpSimilarProductsLink,
    pdpSimilarProducts,
    pdpTechSpecs,
    exclusiveFeatures,
    device,
    trackCustomMetric,
    vanityUrl,
    ctoConfig,
    addOns,
    ugcAvailable,
}) => {
    const { sku, eol } = productInitial || {};
    const addonProduct = addOns && getAddon(sku, addOns);
    const { prices } = useProductPrice([productInitial, ...(addonProduct ? [addonProduct] : [])], {
        withCTOConfigurations: true,
    });
    const { cartId } = useCartState();

    const { stickyNavGtmActions } = productInitial || {};
    const { addCTOToCart, addToCart, getCTOLink } = useAddToCart(false, { withCTOConfigurations: true });

    if (productInitial && device !== 'mobile') {
        const { sku, ctaReplacementURL, ctaAddToCart, ctaEol = 'VIEW ALTERNATE PRODUCT', ctaEolUrl } = productInitial;
        const { isNotDefaultColor, isNotDefaultConfig } = ctoConfig || {};
        const productPrice = prices[sku];
        const anchorLinks = getAnchorLinks(
            productInitial,
            pdpSpecialOffers,
            pdpAccessories,
            pdpSimilarProductsLink,
            pdpSimilarProducts,
            exclusiveFeatures,
            pdpTechSpecs,
            productPrice,
            ugcAvailable
        );

        const isCTO = productInitial.product_type === 'CTO';
        const eolCTA = ctaEolUrl ? { children: ctaEol, to: ctaEolUrl } : null;
        const replacementCTA = ctaReplacementURL
            ? { children: ctaAddToCart, to: ctaReplacementURL }
            : eolCTA
            ? { children: 'NOT AVAILABLE', disabled: true, to: true }
            : null;
        const hideCTA =
            (eol && !eolCTA) ||
            (isCTO && (isNotDefaultColor || isNotDefaultConfig)) ||
            (productPrice && productPrice.hideAddToCart);
        return (
            anchorLinks.length > 0 && (
                <AddOnPrice sku={sku} price={productPrice}>
                    {({ totalPrice }) => {
                        let productProps = getStickyNavProductProps(productInitial, totalPrice, undefined, undefined, {
                            list: 'pdp',
                            cartId,
                            enableCtoAddToCart: true,
                            selectedAddonSku: addonProduct && addonProduct.sku,
                            prices,
                        });
                        if (Helpers.isGiftCard(productInitial)) {
                            productProps.disablePurchase = !!ctoConfig.error;
                            productProps.translations.buttons.purchaseBtnText = productPrice.ctaText;
                        }
                        return (
                            <StickyNAV
                                productProps={productProps}
                                anchorLinks={anchorLinks}
                                onAdd={
                                    hideCTA
                                        ? null
                                        : () => {
                                              if (isCTO) {
                                                  addCTOToCart(productInitial, 1);
                                              } else if (eolCTA) {
                                                  document.location.href = eolCTA.to;
                                              } else {
                                                  //TODO: temporary until Xsell refactor is complete
                                                  let mainBtn = document.getElementById('pdpAddToCartBtn');
                                                  if (mainBtn) {
                                                      // prevents additional addToCart event when clicking on the other button.
                                                      clickPdpAddToCartWithoutGtmAttributes(mainBtn);
                                                  } else {
                                                      addToCart(productInitial, 1);
                                                  }
                                              }
                                          }
                                }
                                getCTOLink={getCTOLink}
                                trackCustomMetric={trackCustomMetric}
                                stickyNavGtmActions={stickyNavGtmActions}
                                eolCTA={eolCTA}
                                replacementCTA={replacementCTA}
                            />
                        );
                    }}
                </AddOnPrice>
            )
        );
    }
    return (
        <AboveTheFoldOnlyServerRender skip={true} renderOn="load">
            <PDPFooter />
        </AboveTheFoldOnlyServerRender>
    );
};

export default withPageComponents(withConfigurations(navContainer), {
    components: [
        'productInitial',
        'pdpSpecialOffers',
        'pdpSimilarProductsLink',
        'pdpSimilarProducts',
        'pdpAccessories',
        'exclusiveFeatures',
        'pdpTechSpecs',
        'ugcAvailable',
    ],
    device: true,
    addOns: true,
});
