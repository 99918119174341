import React from 'react';

import withPageComponents from '../../../page/components/with-page-components';

const StickyWrapper = ({ isSticky, children }) => {
    const stickyStyles = {
        position: 'sticky',
        top: 0,
    };
    if (!isSticky) {
        return children;
    }
    return <div style={stickyStyles}>{children}</div>;
};

export default withPageComponents(
    ({ pdpShadowCTO, children }) => {
        const { shadowCTOEnabled } = pdpShadowCTO || {};
        const isSticky = shadowCTOEnabled;
        return <StickyWrapper isSticky={isSticky}>{children}</StickyWrapper>;
    },
    {
        components: ['pdpShadowCTO'],
    }
);
